import Vue from 'vue';
import Vuex from 'vuex';
import plugins from '@/store/plugins';
import chat from '@/store/modules/chat';
import user from '@/store/modules/user';
import site from '@/store/modules/site';
import subscribe from '@/store/modules/subscribe';
import authentication from "@/store/modules/authentication";
import fxsettings from "@/store/modules/fxsettings";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    getters: {},
    modules: {
        chat,
        site,
        user,
        subscribe,
        authentication,
        fxsettings,
    },
    plugins
})
