<template>
    <div>
        <Spinner></Spinner>
        <h2>Logging out</h2>
        <div class="my-3">
            <!--<router-link class="mr-3" to="/"><i class="fas fa-home"></i> Go Home</router-link>-->
        </div>
    </div>
</template>
<script>
    import Spinner from "./components/UIComponents/Spinner";
    export default {
        name: 'logout',
        components:{
            Spinner
        },
        async created() {
            localStorage.clear();
            const {targetUrl} = this.$route.query;
            await this.$router.push(targetUrl);
        }
    }
</script>
<style scoped>
    a {
        color: #42b983;
    }

</style>
