<template>
    <div class="h-100" :class="{'login-bg-image': $route.fullPath.indexOf('/login') > -1 }">
        <transition name="fade" mode="out-in">
            <router-view></router-view>
        </transition>
    </div>
</template>

<script>
    //import {mapState} from "vuex";

    export default {
        name: 'app',
        computed: {
            /*...mapState({
              user(state) {
                return state.user.oktaUser
              },
            })*/
        },
        data: function () {
            return {authenticated: false}
        },
        created() {
            this.isAuthenticated()
            //console.log(this.$route.fullPath);
          this.handleFavicon()
        },
        watch: {
            // Everytime the route changes, check for auth status
            '$route': 'isAuthenticated'
        },
        methods: {
            async isAuthenticated() {
                this.authenticated = await this.$auth.isAuthenticated
            },
            handleFavicon(){
              const baseUrl = '/';
              const applyIcon = (icon) => {
                const rel = 'icon';
                document?.head
                    .querySelectorAll(`link[rel*="${rel}"]`)
                    .forEach(el => el.href = `${baseUrl}${icon}`)
              };

              const applyTitle = (title) => {
                document?.head
                    .querySelectorAll(`title`)
                    .forEach(el => el.innerHTML = `${title}`)
              };

              let faviconName;// = location.hostname.indexOf('remotefilming.tv') > 0 ? 'favicon-rf.ico' : 'favicon-hl.png';
              let pageTitle;
              if(location.hostname.indexOf('remotefilming.tv') > -1){
                faviconName = 'favicon-rf.ico';
                pageTitle = 'Remote Filming'
              }else {
                faviconName = 'favicon-hl.png';
                pageTitle = 'HIJACK LIVE'
              }
              applyIcon(faviconName);
              applyTitle(pageTitle);
            },
        }
    }
</script>


<style>
    @import url("https://fonts.googleapis.com/css?family=Material+Icons");

    .position-relative {
        position: relative;
    }

    .float-right{
        float: right;
    }
    .float-left{
        float: left;
    }

    .overflow-hidden {
        overflow: hidden;
    }

    .gly-rotate-45 {
        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0.5);
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        display: inline-block;
    }

    .gly-rotate-90 {
        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0.5);
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        display: inline-block;
    }

    .login-bg-image {
        background-image: url(https://ok12static.oktacdn.com/fs/bco/7/fs06wwo5iPi7kAYKq5d6);
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: cover;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }


    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */
    {
        opacity: 0
    }

    .md-app-container {
        overflow-x: hidden !important;
    }

    .md-inset > .md-list-item-container > .md-list-item-content {
        padding-left: 2em !important;
    }

    .md-list-item-content.md-ripple:hover {
        background-color: transparent;
    }

    #vidWin {
        width: 100%;
    }

    .red {
        color: red !important;
    }

    .red-background {
        background-color: red !important;
    }

    .white {
        color: white !important;
    }

    .text-left {
        text-align: left !important;
    }

    .text-right {
        text-align: right !important;
    }

    .md-button {
        text-transform: none
    }

    .absolute {
        position: absolute;
    }

    .center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bg-black {
        background-color: black;
    }

    i.md-icon.md-icon-font.md-theme-default {
        color: white;
    }

    .md-button-content div {
        color: white;
    }

    .bg-dark {
        background-color: #2e2e2e !important;
    }

    .border-dark {
        border: 1px solid #2e2e2e !important;
    }

    .box-shadow-none {
        box-shadow: none;
    }

    .md-bottom-bar.md-theme-default.md-type-fixed {
        box-shadow: none;
    }

    .h-50 {
        height: 50% !important;
    }

    .m-0 {
        margin: 0 !important;
    }

    input[type=range].volume {
        margin: 3.8px 0;
        background-color: transparent;
        -webkit-appearance: none;
    }
    input[type=range].volume:focus {
        outline: none;
    }
    input[type=range].volume::-webkit-slider-runnable-track {
        background: #ffffff;
        border: 0.2px solid #010101;
        border-radius: 1.3px;
        width: 100%;
        height: 8.4px;
        cursor: pointer;
    }
    input[type=range].volume::-webkit-slider-thumb {
        margin-top: -4px;
        width: 16px;
        height: 16px;
        background: #ffffff;
        border: 1px solid #000000;
        border-radius: 14px;
        cursor: pointer;
        -webkit-appearance: none;
    }
    input[type=range].volume:focus::-webkit-slider-runnable-track {
        background: #ffffff;
    }
    input[type=range].volume::-moz-range-track {
        background: #ffffff;
        border: 0.2px solid #010101;
        border-radius: 1.3px;
        width: 100%;
        height: 8.4px;
        cursor: pointer;
    }
    input[type=range].volume::-moz-range-thumb {
        width: 16px;
        height: 16px;
        background: #ffffff;
        border: 1px solid #000000;
        border-radius: 16px;
        cursor: pointer;
    }
    input[type=range].volume::-ms-track {
        background: transparent;
        border-color: transparent;
        border-width: 4.8px 0;
        color: transparent;
        width: 100%;
        height: 8.4px;
        cursor: pointer;
    }
    input[type=range].volume::-ms-fill-lower {
        background: #ffffff;
        border: 0.2px solid #010101;
        border-radius: 2.6px;
    }
    input[type=range].volume::-ms-fill-upper {
        background: #ffffff;
        border: 0.2px solid #010101;
        border-radius: 2.6px;
    }
    input[type=range].volume::-ms-thumb {
        width: 16px;
        height: 16px;
        background: #ffffff;
        border: 1px solid #000000;
        border-radius: 16px;
        cursor: pointer;
        margin-top: 0px;
        /*Needed to keep the Edge thumb centred*/
    }
    input[type=range].volume:focus::-ms-fill-lower {
        background: #ffffff;
    }
    input[type=range].volume:focus::-ms-fill-upper {
        background: #ffffff;
    }
    /*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
    how to remove the virtical space around the range input in IE*/
    @supports (-ms-ime-align:auto) {
        /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
        input[type=range].volume {
            margin: 0;
            /*Edge starts the margin from the thumb, not the track as other browsers do*/
        }
    }


    input[type=range].fxMenu {
        margin: 3.8px 0;
        background-color: transparent;
        -webkit-appearance: none;
    }
    input[type=range].fxMenu:focus {
        outline: none;
    }
    input[type=range].fxMenu::-webkit-slider-runnable-track {
        background: rgba(0,0,0,.38);
        border: 0.2px solid #010101;
        border-radius: 1.3px;
        width: 100%;
        height: 6.4px;
        cursor: pointer;
    }
    input[type=range].fxMenu::-webkit-slider-thumb {
        margin-top: -6px;
        width: 16px;
        height: 16px;
        background: darkgrey;
        border: 1px solid #000000;
        border-radius: 14px;
        cursor: pointer;
        -webkit-appearance: none;
    }
    input[type=range].fxMenu:focus::-webkit-slider-runnable-track {
        background: rgba(0,0,0,.38);
    }
    input[type=range].fxMenu::-moz-range-track {
        background: rgba(0,0,0,.38);
        border: 0.2px solid #010101;
        border-radius: 1.3px;
        width: 100%;
        height: 6.4px;
        cursor: pointer;
    }
    input[type=range].fxMenu::-moz-range-thumb {
        width: 16px;
        height: 16px;
        background: rgba(0,0,0,.38);
        border: 1px solid #000000;
        border-radius: 16px;
        cursor: pointer;
    }
    input[type=range].fxMenu::-ms-track {
        background: transparent;
        border-color: transparent;
        border-width: 4.8px 0;
        color: transparent;
        width: 100%;
        height: 6.4px;
        cursor: pointer;
    }
    input[type=range].fxMenu::-ms-fill-lower {
        background: rgba(0,0,0,.38);
        border: 0.2px solid #010101;
        border-radius: 2.6px;
    }
    input[type=range].fxMenu::-ms-fill-upper {
        background: rgba(0,0,0,.38);
        border: 0.2px solid #010101;
        border-radius: 2.6px;
    }
    input[type=range].fxMenu::-ms-thumb {
        width: 16px;
        height: 16px;
        background: darkgrey;
        border: 1px solid #000000;
        border-radius: 16px;
        cursor: pointer;
        margin-top: 0px;
        /*Needed to keep the Edge thumb centred*/
    }
    input[type=range].fxMenu:focus::-ms-fill-lower {
        background: rgba(0,0,0,.38);
    }
    input[type=range].fxMenu:focus::-ms-fill-upper {
        background: rgba(0,0,0,.38);
    }
    /*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
    how to remove the virtical space around the range input in IE*/
    @supports (-ms-ime-align:auto) {
        /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
        input[type=range].fxMenu {
            margin: 0;
            /*Edge starts the margin from the thumb, not the track as other browsers do*/
        }
    }


</style>
