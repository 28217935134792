import Vue from 'vue';
import render from './render';
import router from './router';
import store from './store';
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import VueMoment from 'vue-moment';
import 'numeral';
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import './assets/styles/main.css'
import VueCarousel from 'vue-carousel';
import Auth0Plugin from "./auth0-plugin";
import Monitor from "./views/Dashboard/Monitor";
import VideoWindow from "./views/components/UIComponents/VideoWindow";
/*Mixin*/
//If you want to copyText from Element - currently not used
async function copyTextFromElement(elementID, elementIconID) {
  let element = document.getElementById(elementID); //select the element
  let icon = document.getElementById(elementIconID);
  let tooltip = document.getElementById(`${elementID}ToolTip`);
  let elementText = element.textContent || element.value; //get the text content from the element

  tooltip.innerText = 'Copied';
  icon.parentElement.style.color = 'blue';

  setTimeout(() => {
    icon.parentElement.style.color = 'unset';
    tooltip.innerText = 'Copy';
  }, 1000);

  try {
    await copyText(elementText); //use the copyText function below
  } catch (e) {
    // console.log(e);
  }
}
async function copyText(text) {
  try {
    await navigator.clipboard.writeText(text);
  } catch (e) {
    // console.log(e);
  }
}
Vue.mixin({
  methods: {
    copyTextFromElement,
    //If you only want to put some Text in the Clipboard just use this function
    // and pass the string to copied as the argument.
    copyText
  }
});
/*Plugins*/
Vue.use(VueMoment);
Vue.use(vueNumeralFilterInstaller, { locale: 'en-gb' });
Vue.use(VueMaterial);
Vue.use(VueCarousel);
Vue.use(Auth0Plugin);
/*Filter*/
function capitalize (value) {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1)
}
function numToSSColumn(num) {
  let s = '', t;
  while (num > 0) {
    t = (num - 1) % 26;
    s = String.fromCharCode(65 + t) + s;
    num = (num - t) / 26 | 0;
  }
  return s || undefined;
}
/**
 * @return {string}
 */
function DefaultCameraLabel(num){
  return `Camera ${numToSSColumn(num)}`;
}
function iframeChatUrl(channel, token){
  //let url = `https://messages.remotefilming.tv/channel/${channel}?layout=embedded`;
  let url = `https://${process.env.VUE_APP_DEV_ROCKETCHAT_DOMAIN}/channel/${channel}?layout=embedded`;
  return token ? `${url}&resumeToken=${token}`: url; //todo - remove staging
}

Vue.filter('DefaultCameraLabel', DefaultCameraLabel);
Vue.filter('iframeChatUrl', iframeChatUrl);
Vue.filter('numToSSColumn', numToSSColumn);
Vue.filter('capitalize', capitalize);


/*Component*/
Vue.component('Monitor', Monitor);
Vue.component('VideoWindow',  VideoWindow);

Vue.config.productionTip = false;
new Vue({
  store,
  router,
  render,
}).$mount('#app');
