<template>
    <div class="h-100">
        <transition name="fade" mode="out-in">
            <!--<md-empty-state v-if="!!userInfo"
                            md-icon="devices_other"
                            md-label="Create your first Domain"
                            md-description="Creating a Domain, you'll be able to create sites to allow collaboration with people.">
                <md-button class="md-primary md-raised" @click="showDialog=true">Create first Domain</md-button>
            </md-empty-state>-->

            <Content v-if="$auth.user"></Content>
            <Spinner v-else></Spinner>

        </transition>

        <md-snackbar md-position="center" :md-active.sync="showSnackbar" md-persistent>
            <span>Domain note added. Showing limited messages!</span>
            <md-button class="md-primary" @click="showSnackbar = false">Close</md-button>
        </md-snackbar>
    </div>
</template>
<script>
    import Spinner from "../../components/UIComponents/Spinner";
    //import {mapState} from "vuex";
    import Content from "@/views/Dashboard/Layout/Content";

    export default {
        components: {
          Spinner,
          Content,
        },
        computed:{
            /*...mapState({
                user(state) {
                    return state.user.oktaUser
                },
            })*/
        },
        data() {
            return {
                userInfo: null,
                showSnackbar: false,
            }
        }
    }
</script>
