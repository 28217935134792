/*import Vue from "vue";
import apiClient from "../../services/apiClient";*/

import apiClient from "../../services/apiClient";

const state = () => ({
    subscribeToken: null,
    error: null
});
//silent update?
const getters = {

};

/*async function getSubscribeTokenInfo(){
    return {"token":"6eb9586c764e6557b7887f98478a7fa8fc08a97686ae2790b4c23f6e006a33d9"}
}*/

const actions = {
    async getSubscribeToken({commit}, subscribeTokenId) {
        commit('updateSubscribeToken', {error: null, data: null});
        const response = {
            error: null,
            data: null
        };

        let data = null;
        let error = null;

        try {
            data = await apiClient.getSubscribeTokenInfo(subscribeTokenId);
        } catch (e) {
            console.log(e);
            error = e
        }
        //console.log(error, data);

        if (data) {
            response.data = data.token;
        }

        commit('updateSubscribeToken', {error, data});
    },
    // eslint-disable-next-line no-unused-vars
    async getStream({commit}, streamName) {
        //commit('updateSubscribeToken', {error: null, data: null});
        const response = {
            error: null,
            data: null
        };

        let data = null;
        let error = null;

        try {
            data = await apiClient.getStreamInfo(streamName);
        } catch (e) {
            console.log(e);
            // eslint-disable-next-line no-unused-vars
            error = e
        }
        //console.log(error, data);

        if (data) {
            response.data = data;
        }

        return Promise.resolve(response.data);

        //commit('updateSubscribeToken', {error, data});
    }
};

const mutations = {
    updateSubscribeToken(state, resp) {
        //console.log('updateSubscribeToken - ', resp);
        state.subscribeToken = resp.data;
        state.error = resp.error
    }
};

const modules = {

};

const user = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    modules
};
export default user
