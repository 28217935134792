<template>
  <div class="w-100 h-100">
    <div class="monitor-viewport h-100">
      <transition name="fade" mode="out-in">
        <div class="text-center main-cont" v-if="subscribeToken && selectedSite">
          <div class="md-layout h-100">

            <div class="md-layout-item md-layout bg-black h-100" style="position: relative;">
              <md-toolbar class="md-accent padding-5px" md-elevation="0">
                <img class="ml-5px toolbar-logo"
                     :src="require(`@/assets/${isHijack ? 'hijack.png' : 'remotefilming.png' }`)"
                     alt="Remotefilming Brand"/>
                <div class="md-toolbar-section-end">
                  <!--<img :src="require(`@/assets/hijack.png`)" alt="Dyson Brand">-->
                </div>
              </md-toolbar>

              <div class="w-100 overflow-hidden overflow-y-scroll-xs video-viewport">
                <div class="md-layout md-alignment-center h-100" v-if="isGrid">
                  <div v-for="(camera, index) in gridCamStreams" :key="`cam-${index}`"
                       class="md-layout-item layout-item m-0 border-dark"
                       :class="{'h-50 md-xlarge-size-50 layout-item md-large-size-50 layout-item md-medium-size-50 layout-item md-small-size-50 md-xsmall-size-100' : gridCamStreams.length>1, 'h-100' : gridCamStreams.length<=1 }">
                    <video-window :ref="`grid-${camera.streamName}`"
                                  :status="statuses[camera.streamName]"
                                  :window-stream="getWindowStream(camera)" :token="subscribeToken"
                                  :framelines="getFrameLines(camera.streamName)"
                                  :center-mark-style="getCenterMarkStyle(camera.streamName)"
                                  :safe-zone-amount="getSafeZoneAmount(camera.streamName)"
                                  :surround-mask-amount="getSurroundMaskValue(camera.streamName)"
                                  :surround-mask-color="getOverlayColor(camera.streamName)"
                                  :apply-fx="applyFx"
                                  :window-type="'main'"></video-window>
                  </div>
                </div>

                <vue-resizable v-else :disable-attributes="['w']" :active="['b']"
                               :height="'calc(100% - 250px)'" :min-height="mainVidMinHeight"
                               :max-height="mainVidMaxHeight" fit-parent
                               class="video-viewport w-100"
                               @resize:move="eHandler" @mount="eHandler" @resize:end="eHandler">
                  <div class="md-layout md-alignment-center h-100" v-if="!isGrid && selectedCamera">
                    <div class="h-100 md-layout-item layout-item md-size-100" style="margin: 0;">
                      <video-window ref="mainVid" :status="statuses[selectedCamera.streamName]"
                                    :window-stream="getWindowStream(selectedCamera)"
                                    :token="subscribeToken" :window-type="'main'"
                                    :framelines="getFrameLines(selectedCamera.streamName)"
                                    :center-mark-style="getCenterMarkStyle(selectedCamera.streamName)"
                                    :safe-zone-amount="getSafeZoneAmount(selectedCamera.streamName)"
                                    :surround-mask-amount="getSurroundMaskValue(selectedCamera.streamName)"
                                    :surround-mask-color="getOverlayColor(selectedCamera.streamName)"
                                    :apply-fx="applyFx"></video-window>
                    </div>
                  </div>
                </vue-resizable>

                <div class="bg-black w-100"
                     v-bind:style="{'height': `calc(100% - ${mainVidHeight}px)`}">
                  <carousel class="h-100" v-if="!isGrid && selectedCamera" :navigationEnabled="true"
                            :paginationEnabled="false" :perPage="itemsToShow">
                    <slide class="h-100" v-for="(camera, index) in camStreams"
                           :key="`cam-${index}`">
                      <!--<div class="video-window-btn-overlay"
                           :class="{'is-active': camera.streamName === selectedCamera.streamName}"
                           @click="onChangeStream(camera)"></div>-->
                      <video-window :ref="`film-${camera.streamName}`" :class="camera.streamName"
                                    :status="statuses[camera.streamName]"
                                    :window-stream="getWindowStream(camera)"
                                    :token="subscribeToken" :window-type="'thumb'"
                                    :on-selected="onChangeStream"
                                    :framelines="getFrameLines(camera.streamName)"
                                    :center-mark-style="getCenterMarkStyle(camera.streamName)"
                                    :safe-zone-amount="getSafeZoneAmount(camera.streamName)"
                                    :surround-mask-amount="getSurroundMaskValue(camera.streamName)"
                                    :surround-mask-color="getOverlayColor(camera.streamName)"
                                    :apply-fx="applyFx"
                      ></video-window>
                    </slide>
                  </carousel>
                </div>
              </div>

              <md-bottom-bar class="md-elevation-0" style="max-height: 48px;">
                <div class="w-100 bg-dark"
                     v-if="selectedSite">
                  <div class="md-layout md-size-100 w-100">
                    <div class="md-layout-item md-layout text-center">
                      <div class="md-layout-item md-size-20  md-medium-text-left">
                        <md-button v-if="allowAppFullScreen" @click=" isFull ? exitFullscreen() : launchFullscreen()">
                          <md-icon class="bottom-bar-icon">open_in_full</md-icon>
                          <div class="md-icon-label">{{'Full'}}</div>
                        </md-button>
                      </div>
                      <div class="md-layout-item text-center md-size-60">
                        <md-button v-if="this.gridCamStreams.length >1"
                                   @click="onDisplay()">
                          <md-icon class="bottom-bar-icon" :class="{'gly-rotate-90': isGrid}">
                            {{!isGrid ? 'grid_view' : 'theaters'}}
                          </md-icon>
                          <div class="md-icon-label">{{!isGrid ? 'Grid' : isPhone ? 'Film' : 'Film Strip'}}</div>
                        </md-button>
                        <md-button v-if="selectedSite.authentication.users"
                                   @click="showViewer = !showViewer">
                          <md-icon class="bottom-bar-icon">groups</md-icon>
                          <div class="md-icon-label">{{'Viewers'}}</div>
                        </md-button>
                        <md-button @click="onShowCameras()">
                          <md-icon class="bottom-bar-icon">videocam</md-icon>
                          <div class="md-icon-label">{{isPhone ? 'Cams' : 'Cameras'}}</div>
                        </md-button>
                        <md-button @click="onShowFX()">
                          <md-icon class="bottom-bar-icon">tune</md-icon>
                          <div class="md-icon-label">{{'FX'}}</div>
                        </md-button>
                        <md-button v-if="selectedSite.chat && selectedSite.chat.enableChat"
                                   @click="onShowChat()">
                          <md-icon class="bottom-bar-icon">chat_bubble</md-icon>
                          <div class="md-icon-label">{{'Chat'}}</div>
                        </md-button>

                      </div>
                      <div class="md-layout-item md-size-20 md-medium-text-right">
                        <md-button v-if="$auth.isAuthenticated()" @click="$auth.logout()">
                          <md-icon class="bottom-bar-icon">logout</md-icon>
                          <div class="md-icon-label">{{'Logout'}}</div>
                        </md-button>
                      </div>
                    </div>
                  </div>
                </div>
              </md-bottom-bar>
            </div>

            <div :class="{'h-100 md-xsmall-size-100 md-small-size-30 md-medium-size-20 md-large-size-20 md-xlarge-size-20 ': !isPhone, 'margin-top-48px md-size-100': isPhone}" class="md-layout-item md-layout position-relative"
                 v-show="showChat || showViewer"
                 id="chatMenuContainer">

              <div class="viewers-container bg-dark" :class="{'chat-container-full' : !showChat && !isPhone}"
                   v-if="selectedSite.authentication.users && showViewer">
                <div class="w-100">
                  <md-button class="md-icon-button float-right" @click="showViewer = !showViewer">
                    <md-icon>close</md-icon>
                  </md-button>
                </div>
                <div class="white text-center padding-top-21">
                  Viewers
                </div>
                <md-list class="users bg-dark" v-if="selectedSite.authentication.users">
                  <md-list-item v-for="(user, index) in selectedSite.authentication.users"
                                :key="`user-${index}`">
                    <md-avatar>
                      <!--<img src="https://placeimg.com/40/40/people/5" alt="People">-->
                      <md-icon>account_circle</md-icon>
                    </md-avatar>

                    <span class="md-list-item-text white">{{user.profile.firstName}} {{user.profile.lastName}}</span>

                    <!--<md-button class="md-icon-button md-list-action">
                        <md-icon class="md-primary">chat_bubble</md-icon>
                    </md-button>-->
                  </md-list-item>
                </md-list>
                <div class="center h-100 bg-dark" v-else>
                  <div class="absolute w-100">
                    <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
                  </div>
                </div>
              </div>

              <div class="w-100" :class="{'chat-container-full' : !showViewer && !isPhone}"
                   v-if="selectedSite.chat"
                   v-show="showChat && selectedSite.chat && selectedSite.chat.enableChat">
                <div class="w-100">
                  <md-button class="md-icon-button" style="position: absolute; right: 0;"
                             @click="onShowChat()">
                    <md-icon>close</md-icon>
                  </md-button>
                </div>
                <iframe ref="iframeChat" id="iframeChat" class="w-100" :class="{'h-100' : !isPhone, 'h-350px': isPhone}"
                        frameBorder="0" :src="iframeChatUrl" @load="onChatChange" v-if="chatToken"
                        v-show="loadedChat"></iframe>
                <div class="center h-100 bg-dark" v-show="!loadedChat">
                  <div class="absolute w-100">
                    <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
                  </div>
                </div>
              </div>
            </div>
            <div :class="{'h-100 md-xsmall-size-100 md-small-size-30 md-medium-size-20 md-large-size-20 md-xlarge-size-20 ': !isPhone, 'margin-top-48px md-size-100': isPhone}" class="md-layout-item md-layout position-relative"
                 v-show="showFX"
                 id="fxMenuContainer">
              <fx-menu :cameras="fxCameraOptions" :on-close="onShowFX"
                       :on-apply-all="onApplyToAllCameraFXs" :on-reset="onResetCameraFX"
                       :on-reset-all="onResetAllCameraFXs"></fx-menu>
            </div>
            <div :class="{'h-100 md-xsmall-size-100 md-small-size-30 md-medium-size-20 md-large-size-20 md-xlarge-size-20 ': !isPhone, 'margin-top-48px md-size-100': isPhone}" class="md-layout-item md-layout position-relative"
                 v-show="showCameras"
                 id="camerasMenuContainer">
              <div class="fx-container w-100" :class="{'chat-container-full' : !isPhone}">
                <md-button class="md-icon-button float-right" @click="onShowCameras()">
                  <md-icon>close</md-icon>
                </md-button>
                <div class="fx-controls" style="padding: 15px;">

                  <md-field class="md-has-value display-block" style="padding-top: 0; margin-top: 0;">
                    <div class="padding-5px" style="margin-bottom: 10px;">
                      <label class="white w-100">Select Cameras to view</label>
                    </div>
                    <md-list class="w-100 bg-dark" style="padding-top: 0;" md-expand-single="true">
                      <md-list-item md-expand md-expanded>
                        <span class="md-list-item-text white">Cameras</span>
                        <md-list slot="md-expand" class="bg-dark"
                                 :md-expanded.sync="selectedSite.cameras.streams.length">
                          <md-list-item
                              v-for="(camera, index) in selectedSite.cameras.streams"
                              :key="`cams-${index}`">
                            <md-checkbox class="white" v-model="selectedCameras"
                                         :value="camera.streamName"
                                         @change="onSelectedCameraChange"/>
                            <span class="md-list-item-text white">{{ getCameraLabel(camera.label, index) }}</span>
                          </md-list-item>

                        </md-list>
                      </md-list-item>
                    </md-list>
                  </md-field>

                </div>
              </div>
            </div>

          </div>
        </div>
        <md-empty-state class="background-none" v-else-if="siteError"
                        md-icon="error"
                        md-label="Error"
                        :md-description="siteError.data">
        </md-empty-state>
        <Spinner v-else></Spinner>
      </transition>
    </div>

  </div>
</template>


<script>

import {mapActions, mapState} from "vuex";
//import MillicastMedia from '@/services/millicast-manager';// eslint-disable-line no-eval
import '@/services/millicast-manager';
import Spinner from "../components/UIComponents/Spinner";
import VideoWindow from "../components/UIComponents/VideoWindow";
import VueResizable from 'vue-resizable';
import FxMenu from "./Partials/FxMenu";
import isMobile from 'ismobilejs';

const EditOptions = {
  //streamName: null,
  framelines: [],
  surroundMaskValue: 0,
  safeZoneAmount: 100,
  overlayColor: {hex: '#FFFFFF'},
  centerMarkStyle: 'none'
};
// eslint-disable-next-line no-unused-vars
const getLocal = (key) => {
  return localStorage.getItem(key);
};
const setLocal = (key, value) => {
  localStorage.setItem(key, value);
};

export default {
  name: 'Monitor',
  components: {FxMenu, Spinner, VideoWindow, VueResizable},
  computed: {
    ...mapState({
      siteError(state) {
        return state.site.error
      },
      selectedSite(state) {
        return state.site.selectedSite;
      },
      subscribeToken(state) {
        return state.subscribe.subscribeToken
      },
      chatToken(state) {
        return state.chat.chatToken
      }
    }),
    isPhone(){
      return isMobile(window.navigator).phone;
    },
    camStreams() {
      let cams = this.gridCamStreams;
      let cam = this.selectedCamera;
      const grid = this.isGrid;
      if (cam && !grid) {
        cams = cams.filter((obj) => {
          return obj.streamName !== this.selectedCamera.streamName;
        });
      }
      //console.log('camStreams ', cams);
      return cams;
    },
    gridCamStreams() {
      let defaultStreams = this.selectedSite.cameras.streams;
      //let activeSrcObjects = this.srcObjects;
      let activeCameras = this.selectedCameras;

      let result = defaultStreams.filter((item) => {
        return activeCameras.some((streamName)=>{
          return streamName === item.streamName;
        });
      });

      console.log('gridCamStreams ', result, activeCameras);
      return result;
    },
  },
  methods: {
    ...mapActions({
      'getSubscribeToken': 'subscribe/getSubscribeToken',
      'getSite': 'site/getSite',
      'getChatToken': 'chat/getChatToken',
      'getStream': 'subscribe/getStream'
    }),
    getCameraLabel(label, index){
      return label ? label : this.$options.filters.DefaultCameraLabel(index+1);
    },
    onSelectedCameraChange(arr) {
      console.log('onSelectedCameraChange', arr);
      /*itemsArray.sort(function(a, b){
          return this.selectedSite.streams.indexOf(a) - sortingArr.indexOf(b);
      });*/
    },
    onResetAllCameraFXs() {
      this.fxCameraOptions.forEach((option) => {
        this.onResetCameraFX(option.streamName);
      });
    },
    onResetCameraFX(streamName) {
      let foundIndex = this.fxCameraOptions.findIndex((item) => {
        return item.streamName === streamName;
      });

      if (foundIndex !== -1) {
        for (let i in EditOptions) {
          this.fxCameraOptions[foundIndex][i] = JSON.parse(JSON.stringify(EditOptions))[i];
        }
        //this.fxCameraOptions[foundIndex] = Object.assign({}, JSON.parse(JSON.stringify(EditOptions)), {streamName:streamName});
      }
    },
    onApplyToAllCameraFXs(streamName) {
      let foundIndex, found = this.fxCameraOptions.find((item, index) => {
        foundIndex = index;
        return item.streamName === streamName;
      });

      if (foundIndex !== -1) {
        this.fxCameraOptions.forEach((option, index) => {
          for (let i in EditOptions) {
            this.fxCameraOptions[index][i] = JSON.parse(JSON.stringify(found))[i];
          }
        });


      }
    },

    getFrameLines(streamName) {
      let found = this.fxCameraOptions.find((item) => {
        return item.streamName === streamName;
      });

      return found.framelines || [];
    },
    getCenterMarkStyle(streamName) {
      let found = this.fxCameraOptions.find((item) => {
        return item.streamName === streamName;
      });

      return found.centerMarkStyle;
    },
    getSurroundMaskValue(streamName) {
      let found = this.fxCameraOptions.find((item) => {
        return item.streamName === streamName;
      });

      return found.surroundMaskValue;
    },
    getSafeZoneAmount(streamName) {
      let found = this.fxCameraOptions.find((item) => {
        return item.streamName === streamName;
      });

      return found.safeZoneAmount;
    },
    getOverlayColor(streamName) {
      let found = this.fxCameraOptions.find((item) => {
        return item.streamName === streamName;
      });
      //console.log('getOverlayColor - ', found.overlayColor);
      return found.overlayColor.hex;
    },
    scrollToEnd: function(elId) {
      let el = this.$el.querySelector(`#${elId}`);
      if (el) {
        el.scrollIntoView({ behavior: "smooth", inline: "end" });
      }
    },
    onShowCameras(_override) {
      this.showCameras = _override ? _override : !this.showCameras;

      if (this.showCameras === true) {
        if (this.showFX === true) {
          this.showFX = false;
        }

        if (this.showChat === true) {
          this.showChat = false;
        }
      }
      window.dispatchEvent(new Event('resize'));

      if(this.isPhone && this.showCameras === true){
        setTimeout(()=>{
          this.scrollToEnd('camerasMenuContainer');
        }, 10);
      }
    },
    onShowFX() {
      this.showFX = !this.showFX;

      if (this.showFX === true) {
        if (this.showCameras === true) {
          this.showCameras = false;
        }

        if (this.showChat === true) {
          this.showChat = false;
        }
      }
      window.dispatchEvent(new Event('resize'));

      if(this.isPhone && this.showFX === true){
        setTimeout(()=>{
          this.scrollToEnd('fxMenuContainer');
        }, 10);
      }

      /*setTimeout(()=>{
          this.onResizeHandler();
      }, 50);*/
    },
    onShowChat() {
      this.showChat = !this.showChat;

      if (this.showChat === true) {
        if (this.showCameras === true) {
          this.showCameras = false;
        }

        if (this.showFX === true) {
          this.showFX = false;
        }
      }
      window.dispatchEvent(new Event('resize'));

      if(this.isPhone && this.showChat === true){
        setTimeout(()=>{
          this.scrollToEnd('chatMenuContainer');
        }, 10);
      }
      /*setTimeout(()=>{
          this.onResizeHandler();
      }, 50);*/
    },
    eHandler(data) {
      if (data.eventName === 'mount') {
        this.mainVidMaxHeight = data.height + 130;
        this.mainVidMinHeight = 120;
      }
      this.mainVidHeight = data.height;
      if (data.eventName === 'resize:end') {
        window.dispatchEvent(new Event('resize'));

        /*if(this.eHandlerBoo)clearTimeout(this.eHandlerBoo);
        this.eHandlerBoo = setTimeout(()=>{
            window.dispatchEvent(new Event('resize'));
        }, 50);*/
      }



      //console.log('eHandler: ', data);
    },
    receiveMessage(event) {
      console.log(event.data)
    },
    logout() {
      this.$auth.logout();
      setTimeout(() => {
        location.reload();
      }, 500);
    },
    getWindowStream(stream) {
      const index = this.selectedSite.cameras.streams.findIndex((camera) => {
        return camera.streamName === stream.streamName;
      });
      const streamName = stream.streamName;
      //let label = stream.label ? stream.label : `Camera ${this.numToSSColumn(index + 1)}`;
      let label = stream.label ? stream.label : this.$options.filters.DefaultCameraLabel(index + 1);
      if (this.selectedSite.display.showCameraLabels === false) label = '';
      //
      let foundSrc = this.srcObjects[streamName]
      /*this.srcObjects.find((obj) => {
        return obj.streamName === streamName;
      });*/
      if (!foundSrc && this.subscribeToken && this.playsTried.indexOf(streamName) === -1) {
        this.tryPlay(streamName)
      }
      //console.log('getWindowStream ', streamName, label, !!foundSrc);
      return{streamName, label, src: foundSrc};
    },
    async getStreamInfo(streamName){
      /*let streamInfo;
      try{
        streamInfo = await this.getStream(streamName)
        if(!streamInfo){
          return new Promise((resolve)=> {
            setTimeout(async () => {
               resolve(await this.getStreamInfo(streamName))
            }, 2000)
          })
        }else{
          return streamInfo
        }
      }catch (e) {
        console.log('getStreamInfo e - ',e)
        setTimeout(async ()=>{
          return await this.getStreamInfo(streamName);
        }, 5000)
      }*/
      return new Promise((resolve) => {
        const interval = setInterval(async() => {
          const result = await this.getStream(streamName);
          if (result) {
            clearInterval(interval); // Clear the interval
            resolve(result); // Resolve with the data
          }
        }, 2500);
      });

    },
    async wait(ms){
      return new Promise((resolve)=>{
        setTimeout(()=>{
          resolve()
        }, ms)
      })
    },
    async tryPlay(streamName) {
      return new Promise((resolve)=>{

        this.playsTried.push(streamName);
        this.getStreamInfo(streamName)
            .then((streamInfo)=>{
              //console.log('getStreamInfo - ',streamInfo)
              return this.playStream(streamInfo);
            })
            .then(()=>{
              //console.log('play wait');
              return this.wait(1000)
            })
            .then(()=>{
              //console.log('play started', );
              this.onResize()
              resolve(this.srcObjects[streamName])
            })
            .catch((e)=>{
              console.log('play started e' , e);
              //const result = this.playsTried.filter((name) => name === streamName);
            });
      })
    },
    onChatChange() {
      //console.log('onChatChange');
      /**/
      const loadChat = () => {
        setTimeout(() => {
          this.loadedChat = true;
        }, 2000);
      };
      setTimeout(() => {
        this.iframeChatUrl = this.$options.filters.iframeChatUrl(this.selectedSite.chat.channel); //todo - remove staging
        loadChat()
      }, 8000);
    },
    onStatus(evt) {
      //console.log('onStatus', evt)
      let displayMode;
      if (this.isGrid) {
        displayMode = 'grid';
      } else {
        displayMode = 'film';
      }
      let elName = `${displayMode}-${evt.streamName}`;
      this.$refs[elName][0].isBroadcasting = evt.status === 'started';
    },
    onFullscreen() {

    },
    launchFullscreen(el) {
      this.isFull = true;
      let element = document.documentElement;
      if (el) element = el;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else {
        this.isFull = false;
      }
    },
    exitFullscreen() {
      this.isFull = false;
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else {
        this.isFull = true;
      }
    },
    onChangeStream(item) {
      //const {streamName} = item;

      const camIndex = this.gridCamStreams.findIndex((camera) => {
        return camera.streamName === item.streamName;
      });
      console.log('onChangeStream - ', camIndex, ' -- ', item);
      // eslint-disable-next-line no-unused-vars
      const prsn = String(this.selectedCamera.streamName);

      this.selectedCamera = item;
      if(this.$refs['mainVid'] && this.srcObjects[this.selectedCamera.streamName])
        this.$refs['mainVid'].addVideoForStream(this.srcObjects[this.selectedCamera.streamName].stream);

      let cams = this.gridCamStreams;
      let cam = this.selectedCamera;
      const grid = this.isGrid;
      if (cam && !grid) {
        cams = cams.filter((obj) => {
          return obj.streamName !== this.selectedCamera.streamName;
        });
      }
      this.camStreams = cams;

      if (this.timeoutID) clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(() => {
        this.camStreams.forEach((camera) => {
          let displayMode = 'film';
          let elName = `${displayMode}-${camera.streamName}`;
          //console.log(elName, this.$refs[elName])
          if (this.srcObjects[camera.streamName])
            this.$refs[elName][0].addVideoForStream(this.srcObjects[camera.streamName].stream);
        });
      }, 10);
    },
    onDisplay(_override) {
      this.isGrid = _override ? _override : !this.isGrid;

      if (!this.selectedCamera) return;

      if (!this.isGrid) {
        this.displayCaret(15);
      }
    },
    addStreamSrc(streamName, stream) {
      //this.srcObjects[streamName] = {streamName, stream};
      let displayMode;
      if (this.isGrid) {
        displayMode = 'grid';
      } else {
        displayMode = 'film';
      }
      let elName = `${displayMode}-${streamName}`;
      //console.log('addStreamSrc', elName, this.$refs);
      let el = this.$refs[elName];//[0] ? this.$refs[elName][0] : this.$refs[elName][0];
      console.log('addStreamSrc', elName, el);
      if (el) {
        if (el[0]) {
          el = el[0];
        }
        el.addVideoForStream(stream);
      } else {
        if (this.isGrid) {
          //do nothing
        } else {
          let elName = `mainVid`;
          //console.log('addStreamSrc', elName, this.$refs);
          let el = this.$refs[elName];//[0] ? this.$refs[elName][0] : this.$refs[elName][0];
          if (el) {
            if (el[0]) {
              el = el[0];
            }
            el.addVideoForStream(stream);
          }
        }
      }
    },
    async getPeerSDP(streamName, iceServers) {
      let own = this;
      let peerConfig = null;
      let sdp = null;
      peerConfig = {
        rtcpMuxPolicy: 'require',  // default
        bundlePolicy: 'max-bundle', // default is balanced
        iceServers
      };
      return new Promise((resolve, reject) => {
        own.peers[streamName] = new RTCPeerConnection(peerConfig);
        let peer = own.peers[streamName];
        let options = {
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
        };
        let audioonly = false;
        // eslint-disable-next-line no-unused-vars
        let muted = true;
        peer.oniceconnectionstatechange = function () {
          // console.log('iceConnectionState ', own.peer.iceConnectionState);
          if (peer.iceConnectionState === 'disconnected') {
            //disconnectedHandler(new Event('disconnected'))
          }
        };
        peer.ontrack = function (event) {
          if (event.receiver.track.kind === "audio" && audioonly === true) {
            //own.addStreamSrc(streamName, event.streams[0], muted);
            return;
          }

          if (event.receiver.track.kind === "video" && audioonly === false) {
            //own.addStreamSrc(streamName, event.streams[0], muted);
            console.log("video", streamName);
            //own.srcObjects.push({streamName: streamName, stream: event.streams[0]});
            own.srcObjects[streamName] = {streamName: streamName, stream: event.streams[0]};
          }
        };
        peer.createOffer(options)
            .then((offer) => {
              sdp = offer.sdp;
              //support for stereo
              offer.sdp = offer.sdp.replace("useinbandfec=1", "useinbandfec=1; stereo=1");
              return peer.setLocalDescription(offer);
            })
            .then(() => {
              // Send the offer to the remote peer using the signaling
              resolve(sdp);
            })
            .catch((e) => {
              reject(e)
            });
      });
    },
    async setRemotePeerSDP(streamName, sdp) {
      return this.peers[streamName].setRemoteDescription({type: 'answer', sdp})
    },
    getWebsocket(streamName, url) {
      let own = this;
      //let url = `${this.director.url}`;
      let ws;
      let error;
      if (!!ws && own.websockets[streamName].readyState === WebSocket.OPEN) {
        return Promise.resolve(ws);
      }
      return new Promise((resolve, reject) => {
        own.websockets[streamName] = ws = new WebSocket(url);
        ws.onopen = () => {
          if (own.websockets[streamName].readyState !== WebSocket.OPEN) {
            error = {state: own.websockets[streamName].readyState};
            return reject(error);
          }
          resolve(ws);
        };
        ws.onerror = (evt) => {
          error = evt || true;
          // console.log('ws::onerror', ws.readyState);
        };
        ws.onclose = () => {
          if (error) {
            reject(`WebSocket Connection Error ${url}`);
          }
          // console.log('ws::onclose', ws.readyState);
          if (own.websockets[streamName].readyState === 3) {
            //ws = null;
            //let peer = this.peers[streamName];/
            //peer = null;
          }
        };
      });
    },
    onmessageHandler(event, resolve, reject) {
      //console.log(event);
      let jsonMsg;
      try {
        let message = event.data;
        jsonMsg = JSON.parse(message);
        //// console.log(jsonMsg);
        if (jsonMsg['type'] === 'error') {
          //console.error(`Signalling error: ${jsonMsg.data}`);
          return reject(`Signalling error: ${message}`);
        }

        // there is only one message ever on a publishing websocket
        // so we don't need to fully add TransactionManager really
        // but this is technically bad form
        // also means we aren't handling response errors at all
        switch (jsonMsg.type) {
          case 'response':
            resolve(jsonMsg.data.sdp);
            break;
          case 'event':
            this.streamStatusHandler(jsonMsg);
            break;
        }

      } catch (err) {
        //console.error(`Failed to handle signalling message: ${err}`);
        return reject(`Failed to handle signalling message: ${err}`);
      }
    },
    streamStatusHandler(evt) {
      //console.log('streamStatusHandler - ', evt);

      let displayMode;
      let streamName = evt.data.streamId.split('/')[evt.data.streamId.split('/').length - 1];
      if (this.isGrid) {
        displayMode = 'grid';
      } else {
        displayMode = 'film';
      }
      let elName = `${displayMode}-${streamName}`;

      if (evt.name === 'active') {
        this.statuses[streamName] = evt.name;
      } else if (evt.name === 'inactive') {
        this.statuses[streamName] = evt.name;
      } else {
        this.statuses[streamName] = 'inactive';
      }

      if (this.$refs[elName] && this.$refs[elName][0]) this.$refs[elName][0].status = this.statuses[streamName];

      let selectedStreamName = this.selectedCamera.streamName;
      this.$refs['mainVid'].status = this.statuses[selectedStreamName];
    },
    getRemotePeerSDP(streamName, ws, sdp) {
      return new Promise((resolve, reject) => {
        let payload = {
          type: 'cmd',//hangs & no error
          transId: new Date().getTime(),//no error
          name: 'view',
          data: {
            sdp: sdp
          }
        };
        let MILLI_PROTO_VER = Number(this.$route.query.v) === 1 ? 'v1' : 'v2';

        if (MILLI_PROTO_VER === 'v1') {
          payload.data.name = streamName;
          payload.data.streamId = streamName;
        }

        ws.onmessage = async (event) => {
          await this.onmessageHandler(event, resolve, reject);
        };
        ws.send(JSON.stringify(payload));
      });
    },
    async playStream(streamInfo) {
      const {streamName, director, iceServers} = streamInfo;
      console.log('playStream ', streamInfo)
      try {
        this.websockets[streamName] = await this.getWebsocket(streamName, director.url);
        this.localSDPs[streamName] = await this.getPeerSDP(streamName, iceServers);
        this.remoteSDPs[streamName] = await this.getRemotePeerSDP(streamName, this.websockets[streamName], this.localSDPs[streamName]);
        await this.setRemotePeerSDP(streamName, this.remoteSDPs[streamName]);

        //if (this.onStatus) this.onStatus({status: 'started', streamName});
      } catch (e) {
        console.log('Play err ', e);
        //if (this.onStatus) this.onStatus('failed');
      }
      return Promise.resolve();
    },

    /*stopPlay() {
        console.log('stopPlay', this.windowStream);

        if (this.peer) this.peer.close();
        this.peer = null;
        if (this.websocket) this.websocket.close();
        this.websocket = null;

        //this.$refs[this.reff].load();
        if (this.onStatus) this.onStatus('stopped');
    },*/
    displayCaret(timeout = 1000) {
      if (!this.isGrid) {
        setTimeout(() => {
          let elArr = document.getElementsByClassName("resizable-b");
          for (let i = 0; i < elArr.length; i++) {
            const el = elArr[i];
            let icon = document.createElement("div");
            icon.className = "caret";
            el.appendChild(icon);
          }
        }, timeout);
      }
    },

    onResize(){
      console.log('onResize');
      this.applyFx = false;
      //this.onChangeStream(this.selectedCamera);
      if(this.eHandlerBoo2)clearTimeout(this.eHandlerBoo2);
      this.eHandlerBoo2 = setTimeout(()=>{
        this.applyFx = true;
      }, 10);
    }
  },
  data() {
    return {
      allowAppFullScreen: true,
      eHandlerBoo2: null,
      eHandlerBoo: null,
      applyFx:false,
      //

      playsTried: [],

      //
      fxCameraOptions: [],
      //
      selectedCameras: [],
      //Menu
      showCameras: false,
      showChat: false,
      showViewer: false,
      isGrid: true,
      showFX: false,
      //Carousel
      itemsToShow: 4,
      //Chat
      loadedChat: false,
      iframeChatUrl: null,

      //millicastMedia: null,

      selectedCamera: null,
      isFull: false,

      //WebRTC
      peers: {},
      srcObjects: [],
      websockets: {},
      localSDPs: {},
      remoteSDPs: {},
      statuses: {},
      //
      timeoutID: null,
      mainVidHeight: 0,
      mainVidMaxHeight: null,
      mainVidMinHeight: null,
      //todo - remove temp data below
      isHijack: null,
    }
  },
  watch: {
    fxCameraOptions: {
      // eslint-disable-next-line no-unused-vars
      handler: function (newVal, oldVal) {
        setLocal('fxCameraOptions', JSON.stringify(newVal));
      },
      deep: true,
      //immediate: true,
    },
    srcObjects:{
      // eslint-disable-next-line no-unused-vars
      handler: function (newVal, oldVal) {
        console.log('srcObjects ', newVal);
      },
      deep: true,
      //immediate: true,
    },
    selectedCameras:{
      // eslint-disable-next-line no-unused-vars
      handler: function (newVal, oldVal) {
        console.log('selectedCameras ', newVal);
        if (newVal.length <= 4) {
          this.itemsToShow = newVal.length - 1 <= 0 ? 1 : newVal.length - 1;

        }else{
          this.itemsToShow = 4;
        }

        if(newVal.length <= 1){
          this.isGrid = true;
        }

        if(newVal.indexOf(this.selectedCamera.streamName) === -1){
          console.log('update');
          this.onChangeStream(this.gridCamStreams[0]);

        }

        window.dispatchEvent(new Event('resize'));
      },
    }
  },
  async created() {
    //console.log('monitor created ', window.location.hostname, window.location.pathname, this.selectedSite);
    //const hostname = 'portal-staging.remotefilming.tv'; //todo - window.location.hostname === 'localhost' ? 'portal-staging.remotefilming.tv' : window.location.hostname;
    const hostnameSplit = location.hostname.split('.');
    const domainName = hostnameSplit.length > 1? hostnameSplit[0] : 'dev.hijacklive.io';

    this.isHijack = location.hostname.indexOf('remotefilming.tv') <= -1;

    const sitePath = window.location.pathname;
    try {
      await this.getSite({domainName, sitePath});
    } catch (e) {
      console.log('getSite error ', e);
    }

    console.log('created!! ', this.siteError);
    if (!this.siteError) {

      let localfxCameraOptions = JSON.parse(getLocal('fxCameraOptions')) || [];

      for (let i = 0; i < this.selectedSite.cameras.streams.length; i++) {
        const {streamName, label} = this.selectedSite.cameras.streams[i];
        let option = !localfxCameraOptions[i] ? null : localfxCameraOptions[i];
        if (option) {
          if (option.streamName === streamName) {
            this.fxCameraOptions.push(Object.assign({label}, JSON.parse(JSON.stringify(option))));
          } else {
            this.fxCameraOptions.push(Object.assign({}, JSON.parse(JSON.stringify(EditOptions)), {streamName, label}));
          }
        } else {
          this.fxCameraOptions.push(Object.assign({}, JSON.parse(JSON.stringify(EditOptions)), {streamName, label}));
        }

        this.selectedCameras.push(streamName);
      }

      this.selectedCamera = this.selectedSite.cameras.streams[0];

      this.applyFx = true;
      if (this.selectedCameras.length <= 4) {
        this.itemsToShow = this.selectedCameras.length - 1 <= 0 ? 1 : this.selectedCameras.length - 1;
        this.isGrid = true;
      } else {
        this.displayCaret();
        this.isGrid = false;
      }

      if(this.isPhone){
        this.allowAppFullScreen = false;
        this.isGrid = false
      }

      console.log('fxCameraOptions ', this.fxCameraOptions);
      console.log('selectedSite - ', this.selectedSite);

      try {
        await this.getSubscribeToken(this.selectedSite.cameras.subscribeTokenId);
      } catch (e) {
        console.log('getSubscribeToken error ', e);
      }

      console.log('SubscribeToken - ', this.subscribeToken);

      console.log('device ', isMobile(window.navigator));

      if (!this.chatToken) {
        try {
          await this.getChatToken(this.selectedSite.chat.channel);
          this.iframeChatUrl = this.$options.filters.iframeChatUrl(this.selectedSite.chat.channel, this.chatToken.token); //todo - remove staging

          setTimeout(() => {
            this.iframeChatUrl = this.$options.filters.iframeChatUrl(this.selectedSite.chat.channel, this.chatToken.token); //todo - remove staging
          }, 1000)
        } catch (e) {
          console.log(e);
        }
      }

      window.addEventListener('resize', this.onResize);

    }
  },
  beforeRouteLeave(to, from, next) {
    next();
  },
  mounted() {
    console.log('mounted');
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.bg-light {
  background-color: #5e5e5e;
}

.md-list-item-content.md-ripple.md-disabled {
  padding-right: 0;
}

.display-block {
  display: block;
}

.md-radio {
  font-size: 10px;
}

.label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.min-height-200px {
  min-height: 200px;
}

.md-button {
  min-width: 40px !important;
}

.md-toolbar {
  min-height: 48px;
  max-height: 48px;
}

.bottom-bar-icon, i.md-icon.md-icon-font.md-theme-default {
  height: 20px !important;
}

.chat-container-full {
  height: calc(100% + 48px);
}

.border {
  border: 1px solid #ccc !important;
}

.video-viewport-filmmode {
  height: calc(100% - 248px);
}

.video-viewport {
  height: calc(100% - 48px);
}


.layout-item {
  /* height: 40px;*/
  margin-top: 8px;
  margin-bottom: 8px;
  /*transition: .3s $md-transition-stand-timing;*/
}

.layout-item:after {
  width: 100%;
  height: 100%;
  display: block;
  /*background: md-get-palette-color(purple, 200);*/
  content: " ";
}


.active-video {
  opacity: .9;
}

.mainVidCont {
  height: calc(100% - 205px);
}

.main-cont {
  height: calc(100% - 48px);
}

.md-bottom-bar > .md-ripple {
  display: inherit;
}

.background-none {
  background: none;
}

.bg-lightgreen {
  background: lightgreen;
}

.bg-green {
  background: green;
}

.bg-darkgreen {
  background: darkgreen;
}


/*Viewer Controls*/
.viewer-controls > .md-button {
  min-width: 36px !important;
}

.md-accent {
  background-color: black !important;
}


small {
  font-size: 10px;
}

img {
  width: 35%;
}

.w-inherit {
  width: inherit;
}

.m-0 {
  margin: 0;
}

.ml-5px {
  margin-left: .5em;
}

.ml-15px {
  margin-left: 1.5em;
}



.bg-transparent-hover {
  background-color: transparent;
}

@media only screen and (min-width: 425px) {
  /*img {
      width: 15%;
  }*/
}

@media (max-width: 425px) {
  .chat-container-full {
    height: calc(100% + 248px);
  }
}

/*@media only screen and (max-width: 425px) {
    .main-cont{
        height: calc(100% - 64px);
    }
    .mainVidCont{
        height: calc(100% - 246px);
    }
}*/

@media (min-width: 960px) {
  .md-medium-text-left {
    text-align: left;
  }

  .md-medium-text-right {
    text-align: right;
  }
}

@media (max-width: 960px) {


  .main-cont {
    height: calc(100% - 48px);
  }
}

@media (max-width: 600px) {

  .main-cont {
    /*height: calc(100% - 56px);*/
  }
}


@media only screen and (min-width: 1440px) {
  /*img {
      width: 10%;
  }*/
}
</style>
<style>
img.ml-5px.toolbar-logo{
  width: auto;
  height: 100%;
}

.h-350px{
  height: 350px!important;
}
.margin-top-48px{
  margin-top: 48px !important;
}

.padding-5px {
  padding: 5px;
}

.padding-0 {
  padding: 0;
}
.margin-0{
  margin: 0;
}

.md-checkbox.white > .md-checkbox-container {
  border-color: white;
}

.md-checkbox.white > .md-checkbox-label {
  color: white;
}

.md-checkbox.md-checked .md-checkbox-container {
  background-color: #909090 !important;
  border-color: white !important;
}

.md-field.md-theme-default:after {
  background-color: #909090 !important;
}

.md-icon.md-theme-default.md-icon-image svg {
  fill: #909090 !important;
}

.md-field.md-theme-default {
  color: #2e2e2e !important;
}

.md-highlight, .md-field.md-theme-default.md-highlight .md-input {
  color: #2e2e2e !important;
}

.md-radio.md-theme-default .md-radio-container {
  border-color: #909090;
}

.md-checked > .md-radio-container {
  border-color: white !important;
}

.md-checked > .md-radio-container:after {
  background-color: white !important;
}

.md-select > .md-input {
  color: white;
  -webkit-text-fill-color: white !important;
  background-color: #2e2e2e;
}

.white > .md-radio-label {
  color: white !important;
}

.fx-container {
  background-color: #2e2e2e;
}

.caret {
  width: 0;
  height: 0;
  display: inline-block;
  border: 7px solid transparent;
  border-top-color: #666666;
  position: absolute;
  right: calc(50% - 7px);
}

.overflow-y-scroll {
  overflow-y: auto;
}

label.md-radio-label {
  font-size: 10px !important;
  padding-left: 5px !important;
}

.layout-item {
  margin: 0;
}

.resizable-b {
  height: 6px !important;
  margin-bottom: 6px;
  background-color: #2e2e2e;
}

.VueCarousel-slide {
  /*max-height: 200px;*/
}

.VueCarousel-navigation-next {
  right: 53px !important;
}

.VueCarousel-navigation-prev, .VueCarousel-navigation-next {
  color: white !important;
  transform: translateY(-50%) translateX(100%) !important;
}

.VueCarousel-wrapper,
.VueCarousel-inner {
  height: 100% !important;
}

.bottom-bar-icon, i.md-icon.md-icon-font.md-theme-default {
  height: 20px !important;
}

ul.md-list.users.bg-dark {
  padding: 0;
  overflow-y: scroll;
  height: calc(100% - 41px);
}

.padding-top-21 {
  padding-top: 21px;
}

.chat-container {
  height: calc(55%);
}

.viewers-container {
  height: calc(45%);
  border-bottom: 1px solid white !important;
}

.video-window-btn-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.video-window-btn-overlay.is-active {
  background: white;
  opacity: .3;
}

.md-overlay.md-fixed.md-dialog-overlay {
  z-index: 8;
}

.bg-white {
  background-color: #fff;
}

.white {
  color: #fff;
}

@media (max-width: 600px) {
  .md-icon {
    font-size: 16px !important;
  }

  div.md-icon-label {
    font-size: 8px !important;
  }


  .md-xsmall-margin-top-48 {
    margin-top: 48px;
  }

  .overflow-y-scroll-xs {
    overflow-y: scroll;
  }

}

</style>

