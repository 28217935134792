import apiClient from "../../services/apiClient";

const state = () => ({
    selectedSite: null,
    error: null
});
//silent update?
const getters = {

};

// eslint-disable-next-line no-unused-vars
async function getSite(domainName, sitePath){
    return {
        "chat": {
            "channel": 1617006152450
        },
        "brands": {
            "main": null,
            "co": null
        },
        "authentication": {
            "users": [
                {
                    "id": "00u2x38ypuHLyL8Hn5d6",
                    "status": "ACTIVE",
                    "created": "2020-12-23T18:40:58.000Z",
                    "activated": null,
                    "statusChanged": "2020-12-24T16:06:45.000Z",
                    "lastLogin": "2021-03-26T11:46:39.000Z",
                    "lastUpdated": "2020-12-24T16:06:45.000Z",
                    "passwordChanged": "2020-12-24T16:06:45.000Z",
                    "type": {
                        "id": "oty2x38vbN1EVcxMm5d6"
                    },
                    "profile": {
                        "firstName": "Alex",
                        "lastName": "Seery",
                        "mobilePhone": null,
                        "secondEmail": null,
                        "login": "alex@hijackpost.com",
                        "email": "alex@hijackpost.com"
                    },
                    "credentials": {
                        "password": {},
                        "recovery_question": {
                            "question": "Where did you meet your spouse/significant other?"
                        },
                        "provider": {
                            "type": "OKTA",
                            "name": "OKTA"
                        }
                    },
                    "_links": {
                        "self": {
                            "href": "https://verification.remotefilming.tv/api/v1/users/00u2x38ypuHLyL8Hn5d6"
                        }
                    }
                },
                {
                    "id": "00u3az0fesown3OBC5d6",
                    "status": "ACTIVE",
                    "created": "2021-01-03T21:12:00.000Z",
                    "activated": "2021-01-03T21:12:00.000Z",
                    "statusChanged": "2021-01-04T17:34:34.000Z",
                    "lastLogin": "2021-03-31T10:44:09.000Z",
                    "lastUpdated": "2021-01-04T17:34:34.000Z",
                    "passwordChanged": "2021-01-04T17:34:34.000Z",
                    "type": {
                        "id": "oty2x38vbN1EVcxMm5d6"
                    },
                    "profile": {
                        "firstName": "Noe",
                        "lastName": "Vega",
                        "mobilePhone": null,
                        "secondEmail": null,
                        "login": "noe@millicast.com",
                        "email": "noe@millicast.com"
                    },
                    "credentials": {
                        "password": {},
                        "recovery_question": {
                            "question": "What is the food you least liked as a child?"
                        },
                        "provider": {
                            "type": "OKTA",
                            "name": "OKTA"
                        }
                    },
                    "_links": {
                        "self": {
                            "href": "https://verification.remotefilming.tv/api/v1/users/00u3az0fesown3OBC5d6"
                        }
                    }
                }
            ]
        },
        "display": {
            "showCameraLabels": false,
            "showLiveDuration": false,
            "allowStreamSelection": true
        },
        "_id": "604fa8226c6c6c439ebe9ae8",
        "createdAt": "2021-03-03T06:53:57.997Z",
        "createdBy": "00u4atgb2icPQ6AEx5d6",
        "path": "/demo",
        "label": "Main Demo",
        "domainId": "603f32862d8b65d8d81bcace",
        "cameras": {
            "publishTokenId": 1164912,
            "subscribeTokenId": 56389966,
            "streams": [
                {
                    "label": null,
                    "streamName": "c954b73e2c4ab06e4057f1bbeaac98dc4f8c25fa"
                },
                {
                    "label": null,
                    "streamName": "623d8109a4620e153946a32076d9dd982237db3c"
                },
                {
                    "label": "Top Angle",
                    "streamName": "f64386d2652ef2190a0d6f4fe5c7b12656953b36"
                },
                {
                    "label": null,
                    "streamName": "2bf3dbad2fb7275c78adeed0d74757e85738d9c0"
                }
            ]
        }
    }

}

const actions = {
    async getSite({commit}, {domainName, sitePath}) {
        commit('updateSelectedSite', {error: null, data: null});
        const response = {
            error: null,
            data: null
        };

        let data = null;
        let error = null;

        try {
            data = await apiClient.getSiteInfo(domainName, sitePath);
        } catch (e) {
            console.log(e);
            error = e
        }
        //console.log(error, data);

        if (data) {
            response.data = data;
        }

        commit('updateSelectedSite', {error, data});
    }
};

const mutations = {
    updateSelectedSite(state, resp) {
        //console.log('updateSelectedSite - ', resp);
        state.selectedSite = resp.data;
        state.error = resp.error
    }
};

const modules = {

};

const site = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    modules
};
export default site
