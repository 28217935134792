<template>
  <div class="w-100 h-100">
    <div class="center h-100">
      <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
    </div>
  </div>
</template>
<script>
  export default{
    name: 'Spinner',
    props: {
      mode: {
        type: String,
        default: 'DEFAULT'
      },
      diameter:{
        type: Number,
        default: 60
      },
      stoke:{
        type: Number,
        default: 6
      }
    }
  }
</script>
<style>
  .spinner {
    display: block;
    position: absolute;
    z-index: 1031; /* High z-index so it is on top of the page */
    top: 45%;
    right: 50%;
  }
</style>
