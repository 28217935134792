<template>
    <div class="fx-container w-100"
         :class="{'overflow-y-scroll':cameras.length>1 ? 'scroll': 'hidden', 'chat-container-full' : !isPhone}">
        <md-button class="md-icon-button float-right" @click="onCloseHandler">
            <md-icon>close</md-icon>
        </md-button>
        <div class="fx-controls" style="">
            <md-field class="md-has-value display-block">
                <div class="padding-5px" style="margin-bottom: 10px;">
                    <label class="white w-100">FX Menu</label>
                </div>
                <div class="bg-dark" style="margin-top: 10px;">
                    <div class="position-relative">

                        <div v-for="(setting, index) in fxSettings"
                             :key="`setting-${index}`"
                             class="padding-0"
                             :class="{'selected-camera-fx-menu': cardStatus === setting.streamName}">
                            <md-field class="md-has-value display-block padding-0  margin-0 menu-field">
                                <div class="w-100 display-flex align-items-center">
                                    <md-switch style="margin: 10px;" v-model="lcameras" :value="setting.streamName" @change="onSelectedCamerasHandler"></md-switch>
                                    <div class="md-list-item-content md-button menu-field w-100 padding-0" style="display: flex;" @click="showCard(setting.streamName)">
                                        <span class="md-list-item-text white text-left padding-0" style="font-size: 15px;">{{ getCameraLabel(setting.label, index) }}</span>
                                    </div>
                                    <md-button class="md-icon-button" @click="onResetFxSettingHandler(setting.streamName)">
                                        <md-icon class="dark-grey  flip-x-180">refresh</md-icon>
                                    </md-button>
                                    <md-icon @click="showCard(setting.streamName)">{{cardStatus === setting.streamName ? 'expand_less' : 'expand_more'}}</md-icon>
                                </div>
                            </md-field>

                            <md-content class="menu-content" v-show="cardStatus === setting.streamName">

                                <div class="display-content-block">
                                    <div class="transform-container">
                                        <md-field class="md-has-value display-block padding-0  margin-0 menu-field">
                                            <div class="w-100 display-flex align-items-center">
                                                <md-switch style="margin: 10px;" v-model="activeTransforms" :value="setting.streamName" @change="onSelectedHandler"></md-switch>
                                                <div class="md-list-item-content md-button menu-field w-100 padding-0" style="display: flex;" @click="onTransformHandler(setting.streamName)">
                                                    <span class="md-list-item-text white text-left padding-0" style="font-size: 15px;">Transform</span>
                                                </div>
                                                <md-button class="md-icon-button" @click="onResetFxSettingTransform({streamName: setting.streamName})">
                                                    <md-icon class="dark-grey  flip-x-180">refresh</md-icon>
                                                </md-button>
                                                <md-icon>{{ transform && transformDisplay[setting.streamName] ? 'expand_less' : 'expand_more'}}</md-icon>
                                            </div>
                                        </md-field>
                                        <md-content class="menu-content" v-show="transform && transformDisplay[setting.streamName]">
                                            <div class="display-content-block">
                                                <div class="bg-dark padding-5px border-darker-grey" style="border-bottom: solid thin;">
                                                    <div class="w-100 display-flex align-items-center">
                                                        <!--<md-switch style="margin: 10px;" v-model="lcameras" :value="camera"></md-switch>-->
                                                        <div class="md-list-item-content menu-field w-100 display-flex">
                                                            <!--<span class="md-list-item-text white" style="font-size: 15px;">{{ getCameraLabel(camera.label, index) }}</span>-->
                                                            <div class="white text-right section-label">Zoom </div>
                                                            <input type="range" class="w-100 fxMenu white"
                                                                   max="200"
                                                                   v-model.number="setting.transform.zoom"
                                                                   :value="setting.transform.zoom"
                                                                   name="transformZoom">
                                                            <div class="bg-darker-grey" style="margin-left: 10px; padding: 5px; border: thin solid black;">
                                                                <span class="white min-width-30px" style="float: right">{{ setting.transform.zoom / 100 | numeral('0.00[0000]') }}</span>
                                                            </div>
                                                            <md-button class="md-icon-button margin-right-0" @click="setting.transform.zoom = 100">
                                                                <md-icon class="dark-grey  flip-x-180">refresh</md-icon>
                                                            </md-button>
                                                        </div>

                                                    </div>

                                                    <div class="w-100 display-flex align-items-center">
                                                        <!--<md-switch style="margin: 10px;" v-model="lcameras" :value="camera"></md-switch>-->
                                                        <div class="md-list-item-content menu-field w-100" style="display: flex;">
                                                            <!--<span class="md-list-item-text white" style="font-size: 15px;">{{ getCameraLabel(camera.label, index) }}</span>-->
                                                            <div class="white text-right section-label" >Position X </div>

                                                            <input type="range" class="w-100 fxMenu white"
                                                                   v-model.number="setting.transform.positionX"
                                                                   :value="setting.transform.positionX"
                                                                   name="surroundMask">
                                                            <div class="bg-darker-grey" style="margin-left: 10px; padding: 5px; border: thin solid black;">
                                                                <span class="white min-width-30px" style="float: right">{{ setting.transform.positionX / 100 | numeral('0.00[0000]') }}</span>
                                                            </div>
                                                            <md-button class="md-icon-button margin-right-0" @click="setting.transform.positionX = 0">
                                                                <md-icon class="dark-grey  flip-x-180">refresh</md-icon>
                                                            </md-button>
                                                        </div>
                                                    </div>

                                                    <div class="w-100 display-flex align-items-center">
                                                        <!--<md-switch style="margin: 10px;" v-model="lcameras" :value="camera"></md-switch>-->
                                                        <div class="md-list-item-content menu-field w-100 display-flex">
                                                            <!--<span class="md-list-item-text white" style="font-size: 15px;">{{ getCameraLabel(camera.label, index) }}</span>-->
                                                            <div class="white text-right section-label">Position Y </div>
                                                            <input type="range" class="w-100 fxMenu white"
                                                                   v-model.number="setting.transform.positionY"
                                                                   :value="setting.transform.positionY"
                                                                   name="safeZone">
                                                            <div class="bg-darker-grey" style="margin-left: 10px; padding: 5px; border: thin solid black;">
                                                                <span class="white min-width-30px" style="float: right">{{ setting.transform.positionY / 100 | numeral('0.00[0000]') }}</span>
                                                            </div>
                                                            <md-button class="md-icon-button margin-right-0" @click="setting.transform.positionY = 0">
                                                                <md-icon class="dark-grey flip-x-180">refresh</md-icon>
                                                            </md-button>
                                                        </div>

                                                    </div>

                                                    <div class="w-100 display-flex align-items-center">
                                                        <!--<md-switch style="margin: 10px;" v-model="lcameras" :value="camera"></md-switch>-->
                                                        <div class="md-list-item-content menu-field w-100 display-flex">
                                                            <!--<span class="md-list-item-text white" style="font-size: 15px;">{{ getCameraLabel(camera.label, index) }}</span>-->
                                                            <div class=" w-auto" style="margin-bottom: 5px; display: flex; justify-content: space-evenly; align-items: center;">
                                                                <div class="white text-right section-label">Rotate </div>

                                                                <md-button class="md-icon-button margin-right-0" @click="setting.transform.rotate = setting.transform.rotate + 90">
                                                                    <md-icon style="transform: rotate(90deg);">screen_rotation</md-icon>
                                                                </md-button>
                                                                <md-button class="md-icon-button margin-right-0" @click="setting.transform.rotate = setting.transform.rotate - 90">
                                                                    <md-icon>screen_rotation</md-icon>
                                                                </md-button>
                                                            </div>
                                                            <md-button class="md-icon-button margin-right-0" @click="setting.transform.rotate = 0">
                                                                <md-icon class="dark-grey  flip-x-180">refresh</md-icon>
                                                            </md-button>
                                                        </div>

                                                    </div>

                                                    <div class="w-100 display-flex align-items-center">
                                                        <!--<md-switch style="margin: 10px;" v-model="lcameras" :value="camera"></md-switch>-->
                                                        <div class="md-list-item-content menu-field w-100 display-flex">
                                                            <!--<span class="md-list-item-text white" style="font-size: 15px;">{{ getCameraLabel(camera.label, index) }}</span>-->
                                                            <div class=" w-auto" style="margin-bottom: 5px; display: flex; justify-content: space-evenly; align-items: center;">
                                                                <div class="white text-right section-label">Flip </div>
                                                                <md-button class="md-icon-button margin-right-0" @click="onFlipX(setting)" :class="{'bg-gray':setting.transform.flip === 'flip-horizontal' || setting.transform.flip === 'flip-both'}">
                                                                    <md-icon style="transform: rotate(90deg);">flip</md-icon>
                                                                </md-button>
                                                                <md-button class="md-icon-button margin-right-0" @click="onFlipY(setting)" :class="{'bg-gray':setting.transform.flip === 'flip-vertically' || setting.transform.flip === 'flip-both'}">
                                                                    <md-icon>flip</md-icon>
                                                                </md-button>
                                                            </div>
                                                            <md-button class="md-icon-button margin-right-0" @click="setting.transform.flip = 'flip-off'">
                                                                <md-icon class="dark-grey  flip-x-180">refresh</md-icon>
                                                            </md-button>
                                                        </div>

                                                    </div>


                                                </div>
                                            </div>
                                        </md-content>
                                    </div>
                                    <div class="cropping-container" v-if="false">
                                        <md-field class="md-has-value display-block padding-0  margin-0 menu-field">
                                            <div class="w-100 display-flex align-items-center">
                                                <md-switch style="margin: 10px;" v-model="activeCroppings" :value="setting.streamName" @change="onSelectedHandler"></md-switch>
                                                <div class="md-list-item-content md-button menu-field w-100 padding-0" style="display: flex;" @click="onCroppingHandler(setting.streamName)">
                                                    <span class="md-list-item-text white text-left padding-0" style="font-size: 15px;">Cropping</span>
                                                </div>
                                                <md-button class="md-icon-button" @click="onResetFxSettingCropping({streamName: setting.streamName})">
                                                    <md-icon class="dark-grey  flip-x-180">refresh</md-icon>
                                                </md-button>
                                                <md-icon>{{cropping && croppingDisplay[setting.streamName]  ? 'expand_less' : 'expand_more'}}</md-icon>
                                            </div>
                                        </md-field>
                                        <md-content class="menu-content" v-show="cropping && croppingDisplay[setting.streamName]">
                                            <div class="display-content-block">
                                                <div class="bg-dark padding-5px border-darker-grey" style="border-bottom: solid thin;">
                                                    <div class="w-100 display-flex align-items-center">
                                                        <!--<md-switch style="margin: 10px;" v-model="lcameras" :value="camera"></md-switch>-->
                                                        <div class="md-list-item-content menu-field w-100 display-flex">
                                                            <!--<span class="md-list-item-text white" style="font-size: 15px;">{{ getCameraLabel(camera.label, index) }}</span>-->
                                                            <div class="white text-right section-label">Crop Left </div>
                                                            <input type="range" class="w-100 fxMenu white"
                                                                   v-model.number="setting.cropping.left"
                                                                   :value="setting.cropping.left"
                                                                   name="safeZone">
                                                            <div class="bg-darker-grey" style="margin-left: 10px; padding: 5px; border: thin solid black;">
                                                                <span class="white min-width-30px" style="float: right">{{ setting.cropping.left / 100 | numeral('0.00[0000]')  }}</span>
                                                            </div>
                                                            <md-button class="md-icon-button margin-right-0" @click="setting.cropping.left = 0">
                                                                <md-icon class="dark-grey  flip-x-180">refresh</md-icon>
                                                            </md-button>
                                                        </div>

                                                    </div>

                                                    <div class="w-100 display-flex align-items-center">
                                                        <!--<md-switch style="margin: 10px;" v-model="lcameras" :value="camera"></md-switch>-->
                                                        <div class="md-list-item-content menu-field w-100" style="display: flex;">
                                                            <!--<span class="md-list-item-text white" style="font-size: 15px;">{{ getCameraLabel(camera.label, index) }}</span>-->
                                                            <div class="white text-right section-label" >Crop Right </div>

                                                            <input type="range" class="w-100 fxMenu white"
                                                                   v-model.number="setting.cropping.right"
                                                                   :value="setting.cropping.right"
                                                                   name="surroundMask">
                                                            <div class="bg-darker-grey" style="margin-left: 10px; padding: 5px; border: thin solid black;">
                                                                <span class="white min-width-30px" style="float: right">{{ setting.cropping.right / 100 | numeral('0.00[0000]') }}</span>
                                                            </div>
                                                            <md-button class="md-icon-button margin-right-0" @click="setting.cropping.right = 0">
                                                                <md-icon class="dark-grey  flip-x-180">refresh</md-icon>
                                                            </md-button>
                                                        </div>
                                                    </div>

                                                    <div class="w-100 display-flex align-items-center">
                                                        <!--<md-switch style="margin: 10px;" v-model="lcameras" :value="camera"></md-switch>-->
                                                        <div class="md-list-item-content menu-field w-100 display-flex">
                                                            <!--<span class="md-list-item-text white" style="font-size: 15px;">{{ getCameraLabel(camera.label, index) }}</span>-->
                                                            <div class="white text-right section-label">Crop Top </div>
                                                            <input type="range" class="w-100 fxMenu white"
                                                                   v-model.number="setting.cropping.top"
                                                                   :value="setting.cropping.top"
                                                                   name="safeZone">
                                                            <div class="bg-darker-grey" style="margin-left: 10px; padding: 5px; border: thin solid black;">
                                                                <span class="white min-width-30px" style="float: right">{{ setting.cropping.top / 100 | numeral('0.00[0000]') }}</span>
                                                            </div>
                                                            <md-button class="md-icon-button margin-right-0" @click="setting.cropping.top = 0">
                                                                <md-icon class="dark-grey  flip-x-180">refresh</md-icon>
                                                            </md-button>
                                                        </div>

                                                    </div>

                                                    <div class="w-100 display-flex align-items-center">
                                                        <!--<md-switch style="margin: 10px;" v-model="lcameras" :value="camera"></md-switch>-->
                                                        <div class="md-list-item-content menu-field w-100" style="display: flex;">
                                                            <!--<span class="md-list-item-text white" style="font-size: 15px;">{{ getCameraLabel(camera.label, index) }}</span>-->
                                                            <div class="white text-right section-label" >Crop Bottom </div>

                                                            <input type="range" class="w-100 fxMenu white"
                                                                   v-model.number="setting.cropping.bottom"
                                                                   :value="setting.cropping.bottom"
                                                                   name="surroundMask">
                                                            <div class="bg-darker-grey" style="margin-left: 10px; padding: 5px; border: thin solid black;">
                                                                <span class="white min-width-30px" style="float: right">{{ setting.cropping.bottom / 100 | numeral('0.00[0000]') }}</span>
                                                            </div>
                                                            <md-button class="md-icon-button margin-right-0" @click="setting.cropping.bottom = 0">
                                                                <md-icon class="dark-grey  flip-x-180">refresh</md-icon>
                                                            </md-button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </md-content>
                                    </div>
                                    <div class="frameLines-container" v-for="(frameLine, index) in setting.frameLines" :key="`frameLine-${index}`">
                                        <md-field class="md-has-value display-block padding-0  margin-0 menu-field">
                                            <div class="w-100 display-flex align-items-center">
                                                <md-switch style="margin: 10px;" v-model="activeFrameLines" :value="`${setting.streamName}-${index}`" @change="onframeLineEnableHandler"></md-switch>
                                                <div class="md-list-item-content md-button menu-field w-100 padding-0" style="display: flex;" @click="onframeLineHandler(setting.streamName, index)">
                                                    <span class="md-list-item-text white text-left padding-0" style="font-size: 15px;">Frame Line {{index+1}}</span>
                                                </div>
                                                <md-button class="md-icon-button" @click="onRemoveFxSettingframeLine({streamName: setting.streamName, index})">
                                                    <md-icon>remove</md-icon>
                                                </md-button>
                                                <md-button class="md-icon-button" @click="onResetFxSettingframeLine({streamName: setting.streamName, index})">
                                                    <md-icon class="dark-grey  flip-x-180">refresh</md-icon>
                                                </md-button>
                                                <md-icon>{{selectedframeLineIndex === index ? 'expand_less' : 'expand_more'}}</md-icon>
                                            </div>
                                        </md-field>
                                        <md-content class="menu-content" v-show="selectedframeLineIndex === index && !resettingframeLines">
                                            <div class="display-content-block">
                                                <div class="bg-dark padding-5px border-darker-grey" style="border-bottom: solid thin;">
                                                    <div class="w-100 display-flex align-items-center">
                                                        <!--<md-switch style="margin: 10px;" v-model="lcameras" :value="camera"></md-switch>-->
                                                        <div class="md-list-item-content menu-field w-100" style="display: flex;">


                                                            <div class="white text-right section-label">Aspect Ratio </div>
                                                            <div class=" w-auto" style="margin-bottom: 5px;">
                                                                <!--frameLine.aspectRatio-->
                                                                <md-select class="" v-model="frameLine.aspectRatio" style="border: thin solid black;">
                                                                    <md-option class="bg-dark" v-for="(frameLineOption, index) in frameLineOptions"
                                                                               :key="`frameLineOption-${index}`" :value="frameLineOption.value">
                                                                        <!--<div class="md-list-item-text white">
                                                                            {{frameLineOption.label}}
                                                                        </div>
                                                                        <div class="md-list-item-text sublabel">
                                                                            {{frameLineOption.sublabel}}
                                                                        </div>-->
                                                                        {{`${frameLineOption.label} ${frameLineOption.sublabel ? `- ${frameLineOption.sublabel}` : ''}`}}
                                                                    </md-option>
                                                                </md-select>
                                                            </div>
                                                            <md-button class="md-icon-button margin-right-0"  @click="frameLine.aspectRatio = 0">
                                                                <md-icon class="dark-grey  flip-x-180">refresh</md-icon>
                                                            </md-button>
                                                        </div>

                                                    </div>

                                                    <div class="w-100 display-flex align-items-center">
                                                        <!--<md-switch style="margin: 10px;" v-model="lcameras" :value="camera"></md-switch>-->
                                                        <div class="md-list-item-content menu-field w-100" style="display: flex;">

                                                            <div class="white text-right section-label">Color </div>
                                                            <div class=" w-auto" style="margin-bottom: 5px;">
                                                                <!--frameLine.color-->
                                                                <md-select class="" v-model="frameLine.color" style="border: thin solid black;">
                                                                    <md-option class="bg-dark" v-for="(color, index) in colorOptions"
                                                                               :key="`color-${index}`" :value="color.value">
                                                                        <!--<div class="md-list-item-text white">
                                                                            {{color}}
                                                                        </div>-->
                                                                        {{color.label | capitalize}}
                                                                    </md-option>
                                                                </md-select>
                                                            </div>
                                                            <md-button class="md-icon-button margin-right-0"  @click="frameLine.color = '#FFFFFF'">
                                                                <md-icon class="dark-grey  flip-x-180">refresh</md-icon>
                                                            </md-button>
                                                        </div>

                                                    </div>

                                                    <div class="w-100 display-flex align-items-center">
                                                        <!--<md-switch style="margin: 10px;" v-model="lcameras" :value="camera"></md-switch>-->
                                                        <div class="md-list-item-content menu-field w-100 display-flex">
                                                            <!--<span class="md-list-item-text white" style="font-size: 15px;">{{ getCameraLabel(camera.label, index) }}</span>-->
                                                            <div class="white text-right section-label">Resize </div>
                                                            <input type="range" class="w-100 fxMenu white"
                                                                   v-model.number="frameLine.resize"
                                                                   :value="frameLine.resize"
                                                                   name="safeZone" id="safeZone">
                                                            <div class="bg-darker-grey" style="margin-left: 10px; padding: 5px; border: thin solid black;">
                                                                <span class="white min-width-30px" style="float: right">{{ frameLine.resize / 100 | numeral('0.00[0000]') }}</span>
                                                            </div>
                                                            <md-button class="md-icon-button margin-right-0" @click="frameLine.resize = 100">
                                                                <md-icon class="dark-grey  flip-x-180">refresh</md-icon>
                                                            </md-button>
                                                        </div>

                                                    </div>

                                                    <div class="w-100 display-flex align-items-center">
                                                        <!--<md-switch style="margin: 10px;" v-model="lcameras" :value="camera"></md-switch>-->
                                                        <div class="md-list-item-content menu-field w-100" style="display: flex;">
                                                            <!--<span class="md-list-item-text white" style="font-size: 15px;">{{ getCameraLabel(camera.label, index) }}</span>-->
                                                            <div class="white text-right section-label" >Surround Shading </div>

                                                            <input type="range" class="w-100 fxMenu white"
                                                                   v-model.number="frameLine.shading"
                                                                   :value="frameLine.shading"
                                                                   name="surroundMask" id="surroundMask">
                                                            <div class="bg-darker-grey" style="margin-left: 10px; padding: 5px; border: thin solid black;">
                                                                <span class="white min-width-30px" style="float: right">{{ frameLine.shading / 100 | numeral('0.00[0000]') }}</span>
                                                            </div>
                                                            <md-button class="md-icon-button margin-right-0" @click="frameLine.shading = 0">
                                                                <md-icon class="dark-grey  flip-x-180">refresh</md-icon>
                                                            </md-button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </md-content>
                                        <md-content class="menu-content" v-show="selectedframeLineIndex === index && resettingframeLines">
                                            <div class="display-content-block">
                                                <div class="bg-dark padding-5px border-darker-grey" style="border-bottom: solid thin;">
                                                    <div class="w-100 display-flex align-items-center" style="min-height: 192px;">
                                                        <spinner></spinner>
                                                    </div>
                                                </div>
                                            </div>
                                        </md-content>
                                    </div>
                                    <div class="add-frameLine-container">
                                        <md-field class="md-has-value display-block padding-0  margin-0 menu-field">
                                            <div class="w-100 display-flex align-items-center">
                                                <div class="md-list-item-content md-button menu-field w-100" style="display: flex;" @click="onAddframeLine(setting.streamName)">
                                                    <span class="md-list-item-text white" style="font-size: 15px; align-items: center;">Add Additional Frame Line</span>

                                                    <div style="width: 40px; min-width: 40px; height: 40px; margin: 0 6px; display: flex;">
                                                        <md-icon class="dark-grey">add_circle_outline</md-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </md-field>
                                    </div>
                                    <div class="master-frameLine-container">
                                        <md-field class="md-has-value display-block padding-0  margin-0 menu-field">
                                            <div class="w-100 display-flex align-items-center">
                                                <md-switch style="margin: 10px;" v-model="activeMasters" :value="setting.streamName" @change="onSelectedHandler"></md-switch>
                                                <div class="md-list-item-content md-button menu-field w-100 padding-0" style="display: flex;" @click="onMasterframeLineHandler(setting.streamName)">
                                                    <span class="md-list-item-text white text-left padding-0" style="font-size: 15px;">Master Frame Line Adjust</span>
                                                </div>
                                                <md-button class="md-icon-button" @click="onResetFxSettingMaster({streamName: setting.streamName})">
                                                    <md-icon class="dark-grey  flip-x-180  flip-x-180">refresh</md-icon>
                                                </md-button>
                                                <md-icon>{{masterframeLine  && masterframeLineDisplay[setting.streamName] ? 'expand_less' : 'expand_more'}}</md-icon>
                                            </div>
                                        </md-field>
                                        <md-content class="menu-content" v-show="masterframeLine  && masterframeLineDisplay[setting.streamName]">
                                            <div class="display-content-block">
                                                <div class="bg-dark padding-5px border-darker-grey" style="border-bottom: solid thin;">

                                                    <div class="w-100 display-flex align-items-center">
                                                        <!--<md-switch style="margin: 10px;" v-model="lcameras" :value="camera"></md-switch>-->
                                                        <div class="md-list-item-content menu-field w-100 display-flex">
                                                            <!--<span class="md-list-item-text white" style="font-size: 15px;">{{ getCameraLabel(camera.label, index) }}</span>-->
                                                            <div class="white text-right section-label">Resize </div>
                                                            <input type="range" class="w-100 fxMenu white"
                                                                   v-model.number="setting.master.resize"
                                                                   :value="setting.master.resize"
                                                                   name="safeZone">
                                                            <div class="bg-darker-grey" style="margin-left: 10px; padding: 5px; border: thin solid black;">
                                                                <span class="white min-width-30px" style="float: right">{{ setting.master.resize / 100 | numeral('0.00[0000]') }}</span>
                                                            </div>
                                                            <md-button class="md-icon-button margin-right-0" @click="setting.master.resize = 100">
                                                                <md-icon class="dark-grey  flip-x-180">refresh</md-icon>
                                                            </md-button>
                                                        </div>

                                                    </div>

                                                    <div class="w-100 display-flex align-items-center">
                                                        <!--<md-switch style="margin: 10px;" v-model="lcameras" :value="camera"></md-switch>-->
                                                        <div class="md-list-item-content menu-field w-100" style="display: flex;">
                                                            <!--<span class="md-list-item-text white" style="font-size: 15px;">{{ getCameraLabel(camera.label, index) }}</span>-->
                                                            <div class="white text-right section-label" >Surround Shading </div>

                                                            <input type="range" class="w-100 fxMenu white"
                                                                   v-model.number="setting.master.shading"
                                                                   :value="setting.master.shading"
                                                                   name="surroundMask">
                                                            <div class="bg-darker-grey" style="margin-left: 10px; padding: 5px; border: thin solid black;">
                                                                <span class="white min-width-30px" style="float: right">{{ setting.master.shading / 100 | numeral('0.00[0000]') }}</span>
                                                            </div>
                                                            <md-button class="md-icon-button margin-right-0" @click="setting.master.shading = 0">
                                                                <md-icon class="dark-grey  flip-x-180">refresh</md-icon>
                                                            </md-button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </md-content>

                                    </div>
                                    <div class="centermarker-container">
                                        <md-field class="md-has-value display-block padding-0  margin-0 menu-field">
                                            <div class="w-100 display-flex align-items-center">
                                                <md-switch style="margin: 10px;" v-model="activeCenterMarkers" :value="setting.streamName" @change="onSelectedHandler"></md-switch>
                                                <div class="md-list-item-content md-button menu-field w-100 padding-0" style="display: flex;" @click="onCenterMarkerHandler(setting.streamName)">
                                                     <span class="md-list-item-text white text-left padding-0" style="font-size: 15px;">Center Marker</span>
                                                </div>
                                                <md-button class="md-icon-button" @click="onResetFxSettingCenterMarker({streamName: setting.streamName})">
                                                     <md-icon class="dark-grey flip-x-180">refresh</md-icon>
                                                </md-button>
                                                 <md-icon>{{centerMarker && centerMarkerDisplay[setting.streamName] ? 'expand_less' : 'expand_more'}}</md-icon>
                                            </div>
                                        </md-field>
                                        <md-content class="menu-content" v-show="centerMarker && centerMarkerDisplay[setting.streamName] && !resettingCenterMarker">
                                            <div class="display-content-block">
                                                <div class="bg-dark padding-5px border-darker-grey" style="border-bottom: solid thin;">
                                                    <div class="w-100 display-flex align-items-center">
                                                        <!--<md-switch style="margin: 10px;" v-model="lcameras" :value="camera"></md-switch>-->
                                                        <div class="md-list-item-content menu-field w-100" style="display: flex;">
                                                            <div class="white text-right section-label">Marker Type </div>
                                                            <div class=" w-auto" style="margin-bottom: 5px;">
                                                                <!--setting.centerMarker.type-->
                                                                <md-select class="" v-model="setting.centerMarker.type" style="border: thin solid black;">
                                                                    <md-option class="bg-dark" v-for="(centerMark, index) in centerMarkOptions"
                                                                               :key="`frameLine-${index}`" :value="centerMark.value">
                                                                        <!--<div class="md-list-item-text white">
                                                                            {{centerMark.label}}
                                                                        </div>-->
                                                                        {{centerMark.label}}
                                                                    </md-option>
                                                                </md-select>
                                                            </div>
                                                            <md-button class="md-icon-button margin-right-0" @click="setting.centerMarker.type = 'none'">
                                                                <md-icon class="dark-grey  flip-x-180">refresh</md-icon>
                                                            </md-button>
                                                        </div>

                                                    </div>

                                                    <div class="w-100 display-flex align-items-center">
                                                        <!--<md-switch style="margin: 10px;" v-model="lcameras" :value="camera"></md-switch>-->
                                                        <div class="md-list-item-content menu-field w-100" style="display: flex;">


                                                            <div class="white text-right section-label">Color </div>
                                                            <div class=" w-auto" style="margin-bottom: 5px;">
                                                                <md-select class="" v-model="setting.centerMarker.color" style="border: thin solid black;">
                                                                    <md-option class="bg-dark" v-for="(color, index) in colorOptions"
                                                                               :key="`color-${index}`" :value="color.value">
                                                                        <!--<div class="md-list-item-text white">
                                                                            {{color}}
                                                                        </div>-->
                                                                        {{color.label | capitalize}}
                                                                    </md-option>
                                                                </md-select>
                                                            </div>
                                                            <md-button class="md-icon-button margin-right-0" @click="setting.centerMarker.color = '#FFFFFF'">
                                                                <md-icon class="dark-grey flip-x-180">refresh</md-icon>
                                                            </md-button>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </md-content>
                                        <md-content class="menu-content" v-show="centerMarker && centerMarkerDisplay[setting.streamName] && resettingCenterMarker">
                                            <div class="display-content-block">
                                                <div class="bg-dark padding-5px border-darker-grey" style="border-bottom: solid thin;">
                                                    <div class="w-100 display-flex align-items-center" style="min-height: 192px;">
                                                        <spinner></spinner>
                                                    </div>
                                                </div>
                                            </div>
                                        </md-content>
                                    </div>
                                    <div class="applytoall-container">
                                        <md-field class="md-has-value display-block padding-0  margin-0 menu-field">
                                            <div class="w-100 display-flex align-items-center">
                                                <div class="md-list-item-content md-button menu-field w-100" style="display: flex;" @click="onApplyAllHandler(setting.streamName)">
                                                    <span class="md-list-item-text white" style="font-size: 15px; align-items: center;">Apply To All Active Cameras</span>

                                                    <div style="width: 40px; min-width: 40px; height: 40px; margin: 0 6px; display: flex;">
                                                        <md-icon class="dark-grey">check_circle_outline</md-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </md-field>
                                    </div>
                                </div>

                            </md-content>
                        </div>


                    </div>

                    <!--<md-button class="bg-light white" @click="onResetAllHandler">Reset All Cameras
                    </md-button>-->
                    <div class="resetall-container">
                        <md-field class="md-has-value display-block padding-0  margin-0 menu-field">
                            <div class="w-100 display-flex align-items-center">
                                <div class="md-list-item-content md-button menu-field w-100" style="display: flex;" @click="onResetAllHandler">
                                    <span class="md-list-item-text white" style="font-size: 15px; align-items: center;">Reset All Cameras</span>

                                    <div style="width: 40px; min-width: 40px; height: 40px; margin: 0 6px; display: flex;">
                                        <md-icon class="dark-grey  flip-x-180">refresh</md-icon>
                                    </div>
                                </div>
                            </div>
                        </md-field>
                    </div>
                </div>
            </md-field>
        </div>

    </div>
</template>

<script>
    import TwitterPicker from "vue-color/src/components/Twitter";
    import isMobile from "ismobilejs";
    import {mapActions, mapMutations, mapState} from "vuex";
    import Spinner from "../../components/UIComponents/Spinner";

    const frameLineOptions = [
        {value: 0, label: 'Off'},
        {value: 1 / 1.78, label: '1:1.78', sublabel: '9x16'},
        {value: 1, label: '1:1', sublabel: 'Square'},
        {value: 1.33, label: '1.33:1', sublabel: '4x3'},
        {value: 1.43, label: '1.43:1', sublabel: 'IMAX Film'},
        //{value: 1.55, label: '1.55:1', sublabel: 'ARRI Open Gate'},
        {value: 1.66, label: '1.66:1', sublabel: 'European Widescreen'},
        {value: 1.78, label: '1.78:1', sublabel: '16x9'},
        {value: 1.85, label: '1.85:1', sublabel: 'Flat DCI'},
        {value: 1.90, label: '1.90:1', sublabel: 'IMAX Digital'},
        {value: 2.00, label: '2.00:1', sublabel: 'Univisium'},
        {value: 2.35, label: '2.35:1', sublabel: '35mm Anamorphic'},
        {value: 2.39, label: '2.39:1', sublabel: 'Cinemascope DCI'}
    ];

    const CenterMarkOptions = [
        {value: 'none', label: 'Off'},
        {value: 'small dot', label: 'Small Dot'},
        {value: 'small cross', label: 'Small Cross'},
        {value: 'dot', label: 'Dot'},
        {value: 'cross', label: 'Cross'}
    ];

    const WordToHex = {
        white: "#FFFFFF",
        red: "#FF0000",
        blue: "#0000FF",
        yellow: "#FFFF00",
        green: "#008000",
        grey: "#808080",
        black: "#000000",
    };

    const HexToWord = Object.fromEntries(Object.entries(WordToHex).map(([k, v]) => [v, k]));

    const colourOptions = [
        {value: '#FFFFFF', label: 'white'},
        {value: '#FF0000', label: 'red'},
        {value: '#0000FF', label: 'blue'},
        {value: '#FFFF00', label: 'yellow'},
        {value: '#008000', label: 'green'},
        {value: '#808080', label: 'grey'},
        {value: '#000000', label: 'black'},
    ];

    //const fromHex = (hex) => HexToWord[hex.toLowerCase()];

    /*const EditOptions = {
        streamName: null,
        frameLines: [],
        surroundMaskValue: 0,
        safeZoneAmount: 100,
        overlayColor: '#FFFFFF',
        centerMarkStyle: 'none'
    };*/

    export default {
        name: "FXMenu",
        // eslint-disable-next-line vue/no-unused-components
        components: {Spinner, TwitterPicker},
        data() {
            return {
                resettingTransform: false,
                resettingCropping: false,
                resettingMaster: false,
                resettingCenterMarker: false,
                resettingframeLines: false,
                //
                transformDisplays:[],
                transformDisplay:{},
                //
                croppingDisplays:[],
                croppingDisplay:{},
                //
                frameLineDisplays:[],
                frameLineDisplay:{},
                //
                masterframeLineDisplays:[],
                masterframeLineDisplay:{},
                //
                centerMarkerDisplays:[],
                centerMarkerDisplay:{},
                //
                cardStatus: null,
                //
                expandSingle: true,
                selectedframeLineOptions: [],
                selectedcolorOptions: [],
                selectedMarkertypeOptions: [],
                colors: Object.keys(HexToWord),
                lcameras:[],
                ltranforms:[],
                lcroppings:[],
                lframeLines:['9f6e4621492b5fd6fd24de4a47219ac7487461d7-0'],
                lmasterframeLines: [],
                lcentermarkers:[],
                transform: false,
                cropping: false,
                frameLines:[{}],
                bframeLine: false,
                selectedframeLineIndex:null,
                masterframeLine: false,
                centerMarker: false,
            }
        },
        props: {
            onSelectedCameras:{
                type: Function,
                default: function () {
                    return null;
                }
            },
            colorOptions: {
                type: Array,
                default: function () {
                    return JSON.parse(JSON.stringify(colourOptions));
                }
            },
            frameLineOptions: {
                type: Array,
                default: function () {
                    return [].concat(frameLineOptions);
                }
            },
            centerMarkOptions: {
                type: Array,
                default: function () {
                    return [].concat(CenterMarkOptions);
                }
            },
            cameras: {
                type: Array,
                default: function () {
                    return null
                }
            },
            onClose: {
                type: Function,
                default: function () {
                    return null
                }
            },
            onReset: {
                type: Function,
                default: function () {
                    return null
                }
            },
            onResetAll: {
                type: Function,
                default: function () {
                    return null
                }
            },
            onApplyAll: {
                type: Function,
                default: function () {
                    return null
                }
            },
        },
        computed:{
            ...mapState({
                fxSettings(state){
                    return state.fxsettings.settings
                },
                activeFrameLines(state){
                    return state.fxsettings.activeFrameLines
                },
                activeTransforms(state){
                    return state.fxsettings.activeTransforms
                },
                activeCroppings(state){
                    return state.fxsettings.activeCroppings
                },
                activeMasters(state){
                    return state.fxsettings.activeMasters
                },
                activeCenterMarkers(state){
                    return state.fxsettings.activeCenterMarkers
                },
            }),
            isPhone(){
                return isMobile(window.navigator).phone;
            },
        },
        methods: {
            ...mapActions({
                'getFxSetting': 'fxsettings/getFxSetting',
                'getDefaultFxSettings': 'fxsettings/getDefaultFxSettings',
                'addFxSettingframeLine': 'fxsettings/addFxSettingframeLine',
                'removeFxSettingframeLine': 'fxsettings/removeFxSettingframeLine',
                'resetFxSettingframeLine': 'fxsettings/resetFxSettingframeLine',
                'resetFxSettingTransform': 'fxsettings/resetFxSettingTransform',
                'resetFxSettingCropping': 'fxsettings/resetFxSettingCropping',
                'resetFxSettingMaster': 'fxsettings/resetFxSettingMaster',
                'resetFxSettingCenterMarker': 'fxsettings/resetFxSettingCenterMarker',
            }),
            ...mapMutations({
                'defaultSettings': 'fxsettings/defaultSettings', // map `this.add()` to `this.$store.commit('increment')`
            }),
            onFlipX(setting){

                if(setting.transform.flip === 'flip-horizontal'){
                    setting.transform.flip = 'flip-off';
                }else if(setting.transform.flip ===  'flip-vertically'){
                    setting.transform.flip = 'flip-both';
                }else if( setting.transform.flip === 'flip-both'){
                    setting.transform.flip = 'flip-vertically';
                }else{
                    setting.transform.flip = 'flip-horizontal'
                }
                //return setting;
            },
            onFlipY(setting){
               if(setting.transform.flip === 'flip-vertically'){
                    setting.transform.flip = 'flip-off';
                }else if(setting.transform.flip ===  'flip-horizontal'){
                    setting.transform.flip = 'flip-both';
                }else if( setting.transform.flip === 'flip-both'){
                    setting.transform.flip = 'flip-horizontal';
                }else{
                    setting.transform.flip = 'flip-vertically'
                }
                //return setting;
            },
            onframeLineEnableHandler(value){
                console.log('onframeLineEnableHandler - ', value);
            },
            onResetFxSettingTransform(payload){
                this.resettingTransform = true;
                this.resetFxSettingTransform(payload);
                setTimeout(()=>{
                    this.resettingTransform = false;
                }, 250);
            },
            onResetFxSettingCropping(payload){
                this.resettingCropping = true;
                this.resetFxSettingCropping(payload);
                setTimeout(()=>{
                    this.resettingCropping = false;
                }, 250);
            },
            onResetFxSettingMaster(payload){
                this.resettingMaster = true;
                this.resetFxSettingMaster(payload);
                setTimeout(()=>{
                    this.resettingMaster = false;
                }, 250);
            },
            onResetFxSettingCenterMarker(payload){
                this.resettingCenterMarker = true;
                this.resetFxSettingCenterMarker(payload);
                setTimeout(()=>{
                    this.resettingCenterMarker = false;
                }, 250);
            },


            onAddframeLine(streamName){
                //const frameLine = {aspectRatio: 0, color:'#FFFFFF', resize: 100, shading: 0, enabled: true};// ?? label:'',
                //this.frameLines.push(frameLine);
                this.addFxSettingframeLine(streamName);
            },
            onframeLineHandler(streamName, index){
                if(this.selectedframeLineIndex === index){
                    this.selectedframeLineIndex = null;
                }else{
                    this.selectedframeLineIndex = index;
                }
            },
            onRemoveFxSettingframeLine(payload){
                this.removeFxSettingframeLine(payload);
            },
            onResetFxSettingframeLine(payload){
                this.resettingframeLines = true;
                this.resetFxSettingframeLine(payload);
                setTimeout(()=>{
                    this.resettingframeLines = false;
                }, 250);
            },


            onMasterframeLineHandler(streamName){
                this.masterframeLineDisplay[streamName] = (this.masterframeLineDisplay[streamName]) ? !this.masterframeLineDisplay[streamName]: true;
                this.masterframeLine = this.masterframeLineDisplay[streamName];

            },
            onCenterMarkerHandler(streamName){
                this.centerMarkerDisplay[streamName] = (this.centerMarkerDisplay[streamName]) ? !this.centerMarkerDisplay[streamName]: true;
                this.centerMarker = this.centerMarkerDisplay[streamName];

                console.log('onCenterMarkerHandler', streamName, this.centerMarker, this.centerMarkerDisplay[streamName]);

            },
            onCroppingHandler(streamName){
                this.croppingDisplay[streamName] = (this.croppingDisplay[streamName]) ? !this.croppingDisplay[streamName]: true;
                this.cropping = this.croppingDisplay[streamName];
            },
            onTransformHandler(streamName){
                this.transformDisplay[streamName] = (this.transformDisplay[streamName]) ? !this.transformDisplay[streamName]: true;
                this.transform = this.transformDisplay[streamName];
            },
            onSelectedHandler(){

            },
            onSelectedCamerasHandler(value){
                if(this.onSelectedCameras)this.onSelectedCameras(value);
            },

            getCameraLabel(label, index){
                return label ? label : this.$options.filters.DefaultCameraLabel(index+1);
            },
            showCard(streamName) {
                console.log(streamName);
                this.transform = false;
                this.transformDisplay[streamName] = this.transform;

                this.cropping = false;
                this.croppingDisplay[streamName] = this.cropping;

                this.masterframeLine = false;
                this.masterframeLineDisplay[streamName] = this.masterframeLine;

                this.centermarker = false;
                this.centerMarkerDisplay[streamName] = this.centermarker;

                this.selectedframeLineIndex = null;

                this.cardStatus = this.cardStatus === streamName ? null : streamName;
            },
            // eslint-disable-next-line no-unused-vars
            onOverlayColorInputHandler(value) {
                //this.editOptions.overlayColor = value.hex;
            },
            onCloseHandler() {
                if (this.onClose) this.onClose();
            },
            delay(n = 150){
                return new Promise((resolve)=>{
                    setTimeout(()=>{
                        resolve();
                    }, n);
                })
            },
            onResetFxSettingHandler(streamName){
                this.getFxSetting(streamName);
            },
            async onResetAllHandler() {
                this.getDefaultFxSettings(this.cameras);
            },
            onApplyAllHandler(streamName) {
                //if (this.onApplyAll) this.onApplyAll(streamName);
                console.log('onApplyAllHandler - ', streamName);
                const found = this.fxSettings.find((setting)=>{
                    return setting.streamName === streamName;
                });

                if(found){
                    //const lfxSettings = [];
                    this.fxSettings.forEach((setting)=>{
                        if(setting.streamName !== found.streamName){
                            const clone  = JSON.parse(JSON.stringify(found));
                            delete clone.streamName;
                            Object.assign(setting, clone);
                            if(this.activeTransforms.indexOf(found.streamName) > -1 ){
                                this.activeTransforms.push(setting.streamName);
                            }else{
                                this.activeTransforms = [];
                            }
                            if(this.activeCroppings.indexOf(found.streamName) > -1 ){
                                this.activeCroppings.push(setting.streamName);
                            }else{
                                this.activeCroppings = [];
                            }
                            if(this.activeMasters.indexOf(found.streamName) > -1 ){
                                this.activeMasters.push(setting.streamName);
                            }else{
                                this.activeMasters = [];
                            }
                            if(this.activeCenterMarkers.indexOf(found.streamName) > -1 ){
                                this.activeCenterMarkers.push(setting.streamName);
                            }else{
                                this.activeCenterMarkers = [];
                            }
                        }

                    });

                }else{
                    //error
                }
            }
        },

        filters: {
            getCenterMarkStyleLabel(value) {
                //console.log('getCenterMarkStyleLabel ',value);
                if (!value) return 'None';
                const found = CenterMarkOptions.find((item) => {
                    return item.value === value;
                });
                if (!found) return 'None';
                return found.label;
            },
            getOverlayColorLabel(value) {
                //console.log('getOverlayColorLabel', value);
                if (!value) return 'None';
                return HexToWord[value];
            },
            getframeLinesLabel(value) {
                console.log('getframeLinesLabel', value);
                if (!value) return 'None';
                const mapped = value.map((item) => {
                    const f = frameLineOptions.find((option) => {
                        return option.value === item;
                    });
                    return f ? f.label : '';
                });
                //if(!found) return 'None';
                return mapped.length ? mapped : 'None';
            }
        },
        async created() {
            //
            /*if (this.cameras.length) {
                // eslint-disable-next-line no-unused-vars
                this.cameras.forEach((item, key) => {
                    this.lcameras.push(item.streamName);
                    this.ltranforms.push(item.streamName);
                    this.lcroppings.push(item.streamName);
                    //this.lmasterframeLines.push(item.streamName);
                    this.lcentermarkers.push(item.streamName);
                })
            }
            function delay(n=250){
                return new Promise((resolve)=>{
                    setTimeout(()=>{
                        resolve()
                    }, n)
                })
            }
            */

            if (this.cameras.length) {
                this.cameras.forEach((item) => {
                    this.lcameras.push(item.streamName);
                });
                this.getDefaultFxSettings(this.cameras);
            }
        },
        watch: {
            /*fxSettings: {
                // eslint-disable-next-line no-unused-vars
                handler: function (newVal, oldVal) {
                    console.log('fxSettings FX MENU', JSON.stringify(newVal));
                },
                deep: true,
                //immediate: true,
            },*/
            /*activeFrameLines: {
                // eslint-disable-next-line no-unused-vars
                handler: function (newVal, oldVal) {
                    console.log('activeFrameLines', JSON.stringify(newVal));
                },
                deep: true,
                //immediate: true,
            }*/
        }

    }
</script>
<style>
    .md-list.md-theme-default .md-selected .md-list-item-content, .md-list.md-theme-default .router-link-active .md-list-item-content{
        color: white;
    }
    .md-list.md-theme-default .md-list-item-container{
        color: darkgrey;
    }
    .md-field.md-has-value .md-input.md-select-value{
        font-size: 12px;
    }
    .min-width-30px{
        min-width: 30px;
    }
    .flip-x-180{
        transform: scaleX(-1);
    }
    .flip-y-180{
        transform: scaleY(-1);
    }
    .dark-grey{
        color: darkgrey !important;
    }
    .darker-grey{
        color: rgba(0,0,0,.38) !important
    }
    .bg-darker-grey, .bg-darker-grey input{
        background-color: rgba(0,0,0,.38) !important
    }
    .border-darker-grey{
        border-color: rgba(0,0,0,.38) !important
    }
    .md-list.md-theme-default .md-list-item-expand.md-active{
        border: none;
    }
    .bg-gray{
        background-color: gray;
    }
    span.md-list-item-text{
        display: inline;
    }
    .md-switch.md-theme-default.md-checked .md-switch-container{
        background-color: rgba(0,0,0,.38);
    }
    .md-field.md-theme-default:before{
        content: none;
    }
    .md-switch-thumb{
        margin-left: 5px;
        width: 10px !important;
        height: 10px !important;
    }
    .margin-right-0{
        margin-right: 0;
    }
    .section-label{
        font-size: 10px;
        min-width: 90px;
        max-width: 90px;
        margin-right: 15px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: darkgrey !important;
    }
    .w-auto{
        width: auto;
    }
    .md-select>input{
        text-align: center;
        width: 100%;
    }
    .padding-left-0{
        padding-left: 0 !important;
    }
    .vc-twitter-triangle, .vc-editable-input, .vc-twitter-hash {
        display: none;
    }

    .list-item-block > .md-list-item-container > .md-list-item-content {
        display: block;
    }

    li.display-content-block > div.md-list-item-container > .md-list-item-content {
        display: block;
    }


    .li-border-none > li > div.md-list-item-expand>{
        border: none;
    }

    .vc-twitter{
        border: none;
        box-shadow: none;
    }

    .vc-twitter-triangle-shadow{
        display: none;
    }

    .selected-camera-fx-menu, .selected-camera-fx-menu > .menu-content, .selected-camera-fx-menu > .menu-field{
        background-color: #2e2e2e;
    }
    .menu-content{
        padding-right: 5px;
        padding-left: 5px;
        margin: auto;
    }

    .md-list.md-theme-default{
        background-color: inherit;
    }

</style>
<style scoped>
    .md-content{
        width: 100%;
        margin: 0;
    }
    .display-flex{
        display: flex;
    }
    .align-content-center{
        align-content: center;
    }
    .align-items-center{
        align-items: center;
    }
    .padding-15{
        padding: 15px;
    }
    span.md-list-item-text.sublabel {
        font-size: 12px;
        color: #909090;
    }

    li.display-content-block .md-list-item-container .md-list-item-content {
        display: block;
    }

    .position-relative {
        position: relative;
    }

    .display-block {
        display: block;
    }
</style>
