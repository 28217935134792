const Transform = {zoom: 100, positionX: 0, positionY: 0, rotate: 0, flip: 'flip-off', enabled: false};
const Cropping = {left: 0, right: 0, top: 0, bottom: 0, enabled: false};
const frameLine = {aspectRatio: 0, color: '#FFFFFF', resize: 100, shading: 0, enabled: true};// ?? label:'',
const frameLines = [JSON.parse(JSON.stringify(frameLine))];
const Master = {resize: 100, shading: 0, enabled: false};
const CenterMarker = {type: 'none', color: '#FFFFFF', enabled: true};

const state = () => ({
    settings: null,
    activeFrameLines: [],
    activeCroppings: [],
    activeTransforms: [],
    activeMasters: [],
    activeCenterMarkers: [],
    error: null
});
//silent update?
const getters = {};

const actions = {
    async getFxSetting({commit}, streamName) {
        const response = {
            error: null,
            data: null
        };

        let data = null;
        let error = null;

        try {
            data = {
                streamName
            };
        } catch (e) {
            console.log(e);
            error = e;
        }

        if (data) {
            response.data = data;
        }

        if (error) {
            response.data = null;
            response.error = error;
        }

        commit('fxSetting', response);
    },
    async getDefaultFxSettings({commit}, cameras) {
        const response = {
            error: null,
            data: null
        };

        let data = null;
        let error = null;

        try {
            data = [];//
            const len = cameras.length;
            for (let i = 0; i < len; i++) {
                console.log(cameras[i]);
                const {streamName, label} = cameras[i];
                let setting = JSON.parse(JSON.stringify({
                    streamName,
                    label,
                    transform: Transform,
                    cropping: Cropping,
                    frameLines: frameLines,
                    master: Master,
                    centerMarker: CenterMarker,
                }));
                data.push(setting);
            }

        } catch (e) {
            console.log(e);
            error = e;
        }

        if (data) {
            response.data = data;
        }

        if (error) {
            response.data = null;
            response.error = error;
        }

        commit('defaultFxSettings', response);
    },
    async addFxSettingframeLine({commit}, streamName) {
        const response = {
            error: null,
            data: null
        };

        let data = null;
        let error = null;
        console.log('addFxSettingframeLine to - ', streamName);
        try {
            data = {
                streamName,
                frameLine: JSON.parse(JSON.stringify(frameLine))
            };//
            //data.frameLines.push(frameLine);
        } catch (e) {
            console.log(e);
            error = e;
        }
        //console.log(error, data);

        if (data) {
            response.data = data;
        }

        if (error) {
            response.data = null;
            response.error = error;
        }

        commit('applyFxSettingframeLine', response);
    },
    async removeFxSettingframeLine({commit}, {streamName, index}) {
        const response = {
            error: null,
            data: null
        };

        let data = null;
        let error = null;
        console.log('removeFxSettingframeLine to - ', streamName);
        try {
            data = {
                streamName,
                index
            };//
            //data.frameLines.push(frameLine);
        } catch (e) {
            console.log(e);
            error = e;
        }
        //console.log(error, data);

        if (data) {
            response.data = data;
        }

        if (error) {
            response.data = null;
            response.error = error;
        }

        commit('clearFxSettingframeLine', response);
    },
    async resetFxSettingframeLine({commit}, {streamName, index}) {
        const response = {
            error: null,
            data: null
        };

        let data = null;
        let error = null;
        console.log('resetFxSettingframeLine to - ', streamName);
        try {
            data = {
                streamName,
                index
            };//
            //data.frameLines.push(frameLine);
        } catch (e) {
            console.log(e);
            error = e;
        }
        //console.log(error, data);

        if (data) {
            response.data = data;
        }

        if (error) {
            response.data = null;
            response.error = error;
        }

        commit('restoreFxSettingframeLine', response);
    },
    async resetFxSettingTransform({commit}, {streamName, index}) {
        const response = {
            error: null,
            data: null
        };

        let data = null;
        let error = null;
        console.log('resetFxSettingframeLine to - ', streamName);
        try {
            data = {
                streamName,
                index
            };//
            //data.frameLines.push(frameLine);
        } catch (e) {
            console.log(e);
            error = e;
        }
        //console.log(error, data);

        if (data) {
            response.data = data;
        }

        if (error) {
            response.data = null;
            response.error = error;
        }

        commit('restoreFxSettingTransform', response);
    },
    async resetFxSettingCropping({commit}, {streamName, index}) {
        const response = {
            error: null,
            data: null
        };

        let data = null;
        let error = null;
        console.log('resetFxSettingframeLine to - ', streamName);
        try {
            data = {
                streamName,
                index
            };//
            //data.frameLines.push(frameLine);
        } catch (e) {
            console.log(e);
            error = e;
        }
        //console.log(error, data);

        if (data) {
            response.data = data;
        }

        if (error) {
            response.data = null;
            response.error = error;
        }

        commit('restoreFxSettingCropping', response);
    },
    async resetFxSettingMaster({commit}, {streamName, index}) {
        const response = {
            error: null,
            data: null
        };

        let data = null;
        let error = null;
        console.log('resetFxSettingframeLine to - ', streamName);
        try {
            data = {
                streamName,
                index
            };//
            //data.frameLines.push(frameLine);
        } catch (e) {
            console.log(e);
            error = e;
        }
        //console.log(error, data);

        if (data) {
            response.data = data;
        }

        if (error) {
            response.data = null;
            response.error = error;
        }

        commit('restoreFxSettingMaster', response);
    },
    async resetFxSettingCenterMarker({commit}, {streamName, index}) {
        const response = {
            error: null,
            data: null
        };

        let data = null;
        let error = null;
        console.log('resetFxSettingCenterMarker to - ', streamName);
        try {
            data = {
                streamName,
                index
            };//
            //data.frameLines.push(frameLine);
        } catch (e) {
            console.log(e);
            error = e;
        }
        //console.log(error, data);

        if (data) {
            response.data = data;
        }

        if (error) {
            response.data = null;
            response.error = error;
        }

        commit('restoreFxSettingCenterMarker', response);
    },
};

const reset = (state, setting) => {
    //const setting = state.settings[index];
    //setting.frameLines.push(frameLine);

    /*let setting = JSON.parse(JSON.stringify({
           streamName,
           label,
           transform: Transform,
           cropping: Cropping,
           frameLines: frameLines,
           master: Master,
           centerMarker: CenterMarker,
       }));*/
    const streamName = setting.streamName;
    if (setting.transform.enabled) {
        if (state.activeTransforms.indexOf(streamName) === -1) state.activeTransforms.push(streamName);
    } else {
        if (state.activeTransforms.indexOf(streamName) > -1) state.activeTransforms.splice(state.activeTransforms.indexOf(streamName), 1);
    }

    if (setting.cropping.enabled) {
        if (state.activeCroppings.indexOf(streamName) === -1) state.activeCroppings.push(streamName);
    } else {
        if (state.activeCroppings.indexOf(streamName) > -1) state.activeCroppings.splice(state.activeCroppings.indexOf(streamName), 1);
    }

    if (setting.master.enabled) {
        if (state.activeMasters.indexOf(streamName) === -1) state.activeMasters.push(streamName);
    } else {
        if (state.activeMasters.indexOf(streamName) > -1) state.activeMasters.splice(state.activeMasters.indexOf(streamName), 1);
    }

    if (setting.centerMarker.enabled) {
        if (state.activeCenterMarkers.indexOf(streamName) === -1) state.activeCenterMarkers.push(streamName);
    } else {
        if (state.activeCenterMarkers.indexOf(streamName) > -1) state.activeCenterMarkers.splice(state.activeCenterMarkers.indexOf(streamName), 1);
    }


    setting.frameLines.forEach((frameLine, index) => {
        const search = `${streamName}-${index}`;
        if (frameLine.enabled) {
            if (state.activeFrameLines.indexOf(search) === -1) state.activeFrameLines.push(search)
        } else {
            if (state.activeFrameLines.indexOf(search) > -1) state.activeFrameLines.splice(state.activeFrameLines.indexOf(search), 1);
        }
    });

};

const mutations = {
    restoreFxSettingframeLine(state, resp) {
        state.settings.forEach((setting, index) => {
            if (setting.streamName === resp.data.streamName) {
                const setting = state.settings[index];
                //const frameLine = setting.frameLines[resp.data.index];
                //setting.frameLines[resp.data.index] = JSON.parse(JSON.stringify(frameLine));
                for(let key in frameLine){
                    setting.frameLines[resp.data.index][key] = frameLine[key];
                }
            }
        });

        console.log('restoreFxSettingframeLine ', resp, state.settings);
    },
    restoreFxSettingTransform(state, resp) {
        state.settings.forEach((setting, index) => {
            if (setting.streamName === resp.data.streamName) {
                const setting = state.settings[index];
                setting.transform = JSON.parse(JSON.stringify(Transform));
            }
        });

        console.log('restoreFxSettingTransform ', resp, state.settings);
    },
    restoreFxSettingCropping(state, resp) {
        state.settings.forEach((setting, index) => {
            if (setting.streamName === resp.data.streamName) {
                const setting = state.settings[index];
                setting.cropping = JSON.parse(JSON.stringify(Cropping));
            }
        });

        console.log('restoreFxSettingCropping ', resp, state.settings);
    },
    restoreFxSettingMaster(state, resp) {
        state.settings.forEach((setting, index) => {
            if (setting.streamName === resp.data.streamName) {
                const setting = state.settings[index];
                setting.master = JSON.parse(JSON.stringify(Master));
            }
        });

        console.log('restoreFxSettingMaster ', resp, state.settings);
    },
    restoreFxSettingCenterMarker(state, resp) {
        state.settings.forEach((setting, index) => {
            if (setting.streamName === resp.data.streamName) {
                const setting = state.settings[index];
                setting.centerMarker = JSON.parse(JSON.stringify(CenterMarker));
            }
        });

        console.log('restoreFxSettingCenterMarker ', resp, state.settings);
    },

    clearFxSettingframeLine(state, resp) {
        state.settings.forEach((setting, index) => {
            if (setting.streamName === resp.data.streamName) {
                const setting = state.settings[index];
                setting.frameLines.splice(resp.data.index, 1);

                const search = `${resp.data.streamName}-${resp.data.index}`;
                if (state.activeFrameLines.indexOf(search) !== -1) {
                    state.activeFrameLines.splice(state.activeFrameLines.indexOf(search), 1);
                }
            }
        });

        console.log('clearFxSettingframeLine ', resp);
    },
    applyFxSettingframeLine(state, resp) {
        const streamName = resp.data.streamName;
        const frameLine = resp.data.frameLine;
        state.settings.forEach((setting, index) => {
            if (setting.streamName === streamName) {
                const setting = state.settings[index];
                setting.frameLines.push(frameLine);
                const search = `${streamName}-${setting.frameLines.length - 1}`;
                if (frameLine.enabled && state.activeFrameLines.indexOf(search) === -1) {
                    state.activeFrameLines.push(search)
                }
            }
        });

        console.log('applyFxSettingframeLine ', state.settings)
    },
    defaultFxSettings(state, resp) {
        console.log('updateFxSettings - ', resp);
        state.settings = resp.data;
        //state.activeFrameLines = [];
        state.error = resp.error;

        state.settings.forEach((setting)=>{
            reset(state, setting);
        });
    },
    fxSetting(state, resp) {
        state.settings.forEach((setting, index) => {
            if (setting.streamName === resp.data.streamName) {
                let setting = state.settings[index];

                for(let key in setting.transform){
                    if(Transform[key] || Transform[key] > -1 )setting.transform[key] = Transform[key];
                }

                for(let key in setting.cropping){
                    if(Cropping[key] || Cropping[key] > -1 )setting.cropping[key] = Cropping[key];
                }

                for(let key in setting.master){
                    if(Master[key] || Master[key] > -1 )setting.master[key] = Master[key];
                }

                for(let key in setting.centerMarker){
                    if(CenterMarker[key] && CenterMarker[key] > -1 )setting.centerMarker[key] = CenterMarker[key];
                }

                setting.frameLines = [JSON.parse(JSON.stringify(frameLine))];

                reset(state, setting);
            }
        });

        console.log('fxSettings ', state.settings);

        state.error = resp.error;

    }
};

const modules = {};

const fxsettings = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    modules
};
export default fxsettings
