import httpClient from "./httpClient";
import Vue from "vue";

const Version = `auth0`;

const errorCatchHandler = function (error) {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        return error.response
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        return error.request
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
        return error
    }
    //console.log(error.config);
};

const getUserInfo = async()=>{
    console.log('getUserInfo');

    let accessToken = await Vue.prototype.$auth.token;
    return new Promise( (resolve, reject)=>{
        let data = null;
        httpClient({
            url:'/api/user',
            method: 'GET',
            //url: resource,
            //data,
            headers: {
               Authorization: `Bearer ${accessToken}`,
                Version
            }
        })
            .then(function (response) {
                data = response.data;
                resolve(data);
            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
                reject(error);
            });
    });
};
const getSubscribeTokenInfo = async(subscribeTokenId)=>{
    console.log('getSubscribeTokenInfo');

    let accessToken = await Vue.prototype.$auth.token;
    return new Promise( (resolve, reject)=>{
        let data = null;
        httpClient({
            url:`/api/subscribe/${subscribeTokenId}`,
            method: 'GET',
            //url: resource,
            //data,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Version
            }
        })
            .then(function (response) {
                data = response.data;
                resolve(data);
            })
            .catch((error)=>{
                reject(errorCatchHandler(error))
            });
    });
};
const getChatTokenInfo = async(channelName)=>{
    console.log('getChatTokenInfo');

    let accessToken = await Vue.prototype.$auth.token;
    return new Promise( (resolve, reject)=>{
        let data = null;
        httpClient({
            url:`/api/rocketchat/token/${channelName}`,
            method: 'GET',
            //url: resource,
            //data,
            headers: {
               Authorization: `Bearer ${accessToken}`,
                Version
            }
        })
            .then(function (response) {
                data = response.data;
                resolve(data);
            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
                reject(error);
            });
    });
};
const getSiteInfo = async (domainName, sitePath) => {
    //const = '/domains/:domainId/users';
    let accessToken = await Vue.prototype.$auth.token;
    return new Promise((resolve, reject) => {
        let data = null;
        httpClient({
            url: `/api/domain/${domainName}/site${sitePath}`,
            //url:`/api/rocketchat/token/${channelName}`,
            method: 'GET',
            //url: resource,
            //data,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Version
            }
        })
            .then(function (response) {
                data = response.data;
                resolve(data);
            })
            .catch((error)=>{
                reject(errorCatchHandler(error))
            });
    });
};

const postLoginInfo = async (targetUrl, password) => {
    console.log('postLoginInfo', targetUrl, password);

    //let accessToken = await Vue.prototype.$auth.token;
    return new Promise( (resolve, reject)=>{
        let data = {
            targetUrl,
            password
        };
        httpClient({
            url:'/api/login',
            method: 'POST',
            //url: resource,
            data,
            headers: {
                //Authorization: `Bearer ${accessToken}`
            }
        })
            .then(function (response) {
                data = response.data;
                resolve(data);
            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
                reject(error);
            });
    });
};

const getStreamInfo = async (streamName) => {
    //const = '/domains/:domainId/users';
    let accessToken = await Vue.prototype.$auth.token;
    return new Promise((resolve, reject) => {
        let data = null;
        httpClient({
            url: `/api/subscribe/info/${streamName}`,
            //url:`/api/rocketchat/token/${channelName}`,
            method: 'GET',
            //url: resource,
            //data,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Version
            }
        })
            .then(function (response) {
                data = response.data;
                resolve(data);
            })
            .catch((error)=>{
                reject(errorCatchHandler(error))
            });
    });
};

export default {
    postLoginInfo,
    getUserInfo,
    getSubscribeTokenInfo,
    getChatTokenInfo,
    getSiteInfo,
    getStreamInfo
};
