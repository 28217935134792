import Vue from "vue";

const state = () => ({
    oktaUser: null,
    error: null
});
//silent update?
const getters = {

};

const actions = {
    async getOktaUser({commit}) {
        commit('updateOktaUser', {error: null, data: null});
        const response = {
            error: null,
            data: null
        };

        let data = null;
        let error = null;

        try {
            data = await Vue.prototype.$auth.getUser();
        } catch (e) {
            console.log(e);
            error = e
        }
        //console.log(error, data);

        if (data) {
            response.data = data;
        }

        commit('updateOktaUser', {error, data});
    }
};

const mutations = {
    updateOktaUser(state, resp) {
        //console.log('updateOktaUser - ', resp);
        state.oktaUser = resp.data;
        state.error = resp.error
    }
};

const modules = {

};

const user = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    modules
};
export default user
