/*import Vue from "vue";
import apiClient from "../../services/apiClient";*/
import Vue from "vue";
import apiClient from "../../services/apiClient";

const state = () => ({
    passwordVerified: null,
    error: null
});
//silent update?
const getters = {

};

const actions = {
    async login({commit}, {targetUrl, password}) {
        commit('updateLogin', {error: null, data: null});
        const response = {
            error: null,
            data: null
        };

        let data = null;
        let error = null;

        try {
            data = await apiClient.postLoginInfo(targetUrl, password);
        } catch (e) {
            console.log(e);
            error = e
        }
        //console.log(error, data);

        if (data) {
            response.data = data;
            Vue.prototype.$auth.targetUrl = targetUrl;
        }

        commit('updateLogin', {error, data});

        return !!data
    }
};

const mutations = {
    updateLogin(state, resp) {
        //console.log('updateLogin - ', resp);
        state.passwordVerified = resp.data;
        state.error = resp.error
    }
};

const modules = {

};

const authentication = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    modules
};
export default authentication
