import auth0 from 'auth0-js'
import Vue from 'vue'

// exchange the object with your own from the setup step above.
const config = {
    domain: process.env.VUE_APP_CLIENT_DOMAIN || 'login.remotefilming.tv',
    clientID: process.env.VUE_APP_CLIENT_ID || '',
    redirectUri: `${window.location.protocol}//${window.location.host}${process.env.VUE_APP_REDIRECT_URI}` || 'http://localhost:3000/auth0/callback',
    responseType: process.env.VUE_APP_RESPONSE_TYPE || 'token id_token',
    scope: process.env.VUE_APP_SCOPE || 'openid profile email'// define the scopes you want to use
};
console.log('config - ',config);
let webAuth = new auth0.WebAuth(config);
//let expiresAtInterval = null;
let auth = new Vue({
    computed: {
        targetUrl: {
            get: function() {
                return localStorage.getItem('target_url')
            },
            set: function(targetUrl) {
                localStorage.setItem('target_url', targetUrl)
            }
        },
        token: {
            get: function() {
                return localStorage.getItem('id_token')
            },
            set: function(id_token) {
                localStorage.setItem('id_token', id_token)
            }
        },
        accessToken: {
            get: function() {
                return localStorage.getItem('access_token')
            },
            set: function(accessToken) {
                localStorage.setItem('access_token', accessToken)
            }
        },
        expiresAt: {
            get: function() {
                return localStorage.getItem('expires_at')
            },
            set: function(expiresIn) {
                let expiresAt = JSON.stringify(expiresIn * 1000 + new Date().getTime());
                localStorage.setItem('expires_at', expiresAt)
            }
        },
        user: {
            get: function() {
                return JSON.parse(localStorage.getItem('user'))
            },
            set: function(user) {
                localStorage.setItem('user', JSON.stringify(user))
            }
        }
    },
    methods: {
        login() {
            webAuth.authorize()
        },
        logout() {
            const targetUrl = this.targetUrl;
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve, reject) => {
                localStorage.removeItem('target_url');
                localStorage.removeItem('access_token');
                localStorage.removeItem('id_token');
                localStorage.removeItem('expires_at');
                localStorage.removeItem('user');
                webAuth.logout({
                    returnTo: `${window.location.protocol}//${window.location.host}${process.env.VUE_APP_LOGOUT_URI}?targetUrl=${targetUrl}`,//`http://localhost:3000/auth0/logout?targetUrl=${targetUrl}`, // Allowed logout URL listed in dashboard
                    clientID: config.clientID
                })
            })
        },
        isAuthenticated() {
            return new Date().getTime() < this.expiresAt
        },
        handleAuthentication() {
            return new Promise((resolve, reject) => {
                webAuth.parseHash((err, authResult) => {

                    if (authResult && authResult.accessToken && authResult.idToken) {
                        this.expiresAt = authResult.expiresIn;
                        this.accessToken = authResult.accessToken;
                        this.token = authResult.idToken;
                        this.user = authResult.idTokenPayload;


                        /*if(expiresAtInterval)clearInterval(expiresAtInterval);
                        expiresAtInterval = setInterval(()=>{
                            //console.log('!!!expires_at!!!', this.expiresAt)
                        }, 1000);*/

                        resolve()

                    } else if (err) {
                        this.logout();
                        reject(err)
                    }

                })
            })
        }
    }
});

export default {
    install: function(Vue) {
        Vue.prototype.$auth = auth
    }
}
