<template>
    <div class="callback"></div>
</template>

<script>
    export default {
        name: 'callback',
        mounted() {
            this.$auth.handleAuthentication().then(() => {
                //console.log('handleAuthentication', this.$auth.targetUrl);
                this.$router.push(this.$auth.targetUrl || '/')
            })
        }
    }
</script>
