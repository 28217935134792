import Vue from 'vue';
import Router from 'vue-router';
//import Auth from '@okta/okta-vue';
//import Store  from '@/store';
import DefaultLayout from './views/Dashboard/Layout/DefaultLayout';
import Monitor from './views/Dashboard/Monitor.vue';
import Logout from './views/Logout.vue'
import Login from "./views/Login";
import Callback from "./views/Callback";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/auth0/callback',
            name: 'callback',
            component: Callback
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
        },
        {
            path: '/auth0/logout',
            name: 'logout',
            component: Logout,
        },
        {
            path: '/:siteId',
            name: 'main',
            redirect: '/',
            component: DefaultLayout,
            children: [{
                path: '',
                name: 'monitor',
                component: Monitor
            }],
            meta: {
                // backPath: '/',
                requiresAuth: true
            },
            //beforeEnter: authGuard
        },
        {
            path: '/unauthorized',
            name: 'unauthorized',
            component: () => {
                return import(/* webpackChunkName: "notfound" */ './views/NotFound.vue')
            },

        },
        {
            path: '*',
            name: 'notfound',
            component: () => {
                return import(/* webpackChunkName: "notfound" */ './views/NotFound.vue')
            },

        },
    ]
});

router.beforeEach((to, from, next) => {
    if (to.name === 'callback') { // check if "to"-route is "callback" and allow access
        next()
    } else if (to.name === 'login') { // if authenticated allow access
        next()
    } else if (to.name === 'logout') { // if authenticated allow access
        next()
    } else if (to.name === 'notfound') { // if authenticated allow access
        next()
    } else if (router.app.$auth.isAuthenticated() && to.fullPath === Vue.prototype.$auth.targetUrl) { // if authenticated allow access
        next()
    } else { // trigger auth0 login
        //router.app.$auth.login()
        router.push(`/login?targetUrl=${to.fullPath}`);
    }
});

export default router;
