<template>
    <div :ref="`main-${reff}`" class="md-layout-item bg-black "
         :class="{'vid-win-active': windowType === 'main', 'h-100': windowType !== 'main'}"
         @mouseover="onMouseover"
         @mouseleave="onMouseleave">
        <div class="center position-relative h-100" @click="onClick">
            <div class="center position-relative h-100 overflow-hidden">
                <video width="" :ref="reff" :class="getClass" @canplay="updatePaused"
                       @playing="updatePaused" @pause="updatePaused" class="vidWin bg-black h-100" autoplay muted
                       playsinline
                       :style="getVideoTransform"></video>
            </div>

            <div class="white bg-black center absolute w-100 h-100 " v-if="status === 'inactive'">
                <img class="contain-img position-relative" :class="{'h-100': containImgHeight}" src="@/assets/STREAM-UNAVAILABLE.png"/>
                <!--Stream Unavailable-->
                <!--<md-progress-spinner v-if="!status" md-mode="indeterminate"></md-progress-spinner>-->
            </div>
            <div class="white absolute center h-100" v-if="applyFx && loaded">

                <div v-if="hasCenterMark" class="w-100 h-100">
                    <img class="contain-img h-100" :class="{'position-relative': centerMarkLoaded}" @load="onImgLoad" :src="require(`@/assets/centermarks/${centerMarkStyle.toUpperCase()}.png`)"/>
                </div>
            </div>
            <div style="position: absolute; width: 100%; height: 100%" v-if="getFrameLines.indexOf(0) === -1 && applyFx && loaded">
                <div style="position: absolute; width: 100%; height: 100%" v-for="(frameLine,index) in getFrameLines" :key="`f-${index}`">
                    <div class="center position-relative h-100" v-if="hasFrameLine(frameLine,index)">
                        <vue-resizable :active="[]" :disable-attributes="[]"
                                       style="border-style: solid; border-width: 2px;"
                                       :style="{ zIndex: Math.round((frameLine.aspectRatio*100)/index), borderColor: frameLine.color }"
                                       :min-height="getFrameHeight(frameLine.aspectRatio, null, null,  frameLine.resize)" :height="getFrameHeight(frameLine.aspectRatio, null, null,  frameLine.resize)"
                                       :min-width="getFrameWidth(frameLine.aspectRatio, null, null,  frameLine.resize)" :width="getFrameWidth(frameLine.aspectRatio, null, null,  frameLine.resize)"  fit-parent >
                            <div class="block">
                                <div class="drag-container-1"></div>
                                <div class="table-container">
                                    <table>
                                    </table>
                                </div>
                                <div class="drag-container-2"></div>
                            </div>
                        </vue-resizable>
                    </div>
                </div>
            </div>
            <div style="position: absolute;" class="w-100 h-100" v-for="(frameLine,index) in getFrameLines" :key="`s-${index}`">
                <div class="center position-relative h-100" v-if="frameLine.aspectRatio>0">
                    <svg width="100%" height="100%" preserveAspectRatio="none" :style="{ opacity: hasMaster ? surroundMaskAmount/100 : frameLine.shading/100 }">
                        <defs>
                            <mask :id="`surround-mask-${windowStream.streamName}-${index}`">
                                <rect width="100%" height="100%" x="0"  y="0" fill="white"/>
                                <rect x="50%" y="50%" :transform="getMaskTranslate()" :width="getMaskFrameWidth()" :height="getMaskFrameHeight()"/>
                            </mask>
                        </defs>
                        <rect id="rect-cont" x="0" y="0" width="100%" height="100%" :mask="`url(#surround-mask-${windowStream.streamName}-${index})`"/>
                    </svg>
                </div>
            </div>
        </div>
        <md-bottom-bar class="md-elevation-0" :class="{'bottom-bar-fullscreen' : isFull}" v-if="windowType === 'main'">
            <div class="w-100 bg-black">
                <div class="md-layout">
                    <div class="md-layout-item md-layout white text-left" :class="{'md-size-30': windowType === 'main' && status === 'active'}">
                        <div class="w-100" style="padding: 8px;">
                            <div v-if="windowStream" class="white ellipsis">{{windowStream.label ? windowStream.label : ''}}</div>
                        </div>
                    </div>
                    <div class="md-layout-item md-layout white text-right" v-if="windowType === 'main' && status === 'active'">
                        <div class="md-layout-item viewer-controls">
                            <md-button @click="setMute()">
                                <md-icon class="white">{{!isMuted ? 'volume_up' : 'volume_off'}}</md-icon>
                            </md-button>
                            <input type="range"
                                   v-model.number="rangeVolume" class="volume">
                            <md-button style="margin-right: 0;"
                                       @click="isFull ? exitFullscreen() : launchFullscreen($refs[`main-${reff}`])">
                                <md-icon class="white">fullscreen</md-icon>
                            </md-button>
                        </div>
                    </div>
                </div>
            </div>
        </md-bottom-bar>
    </div>
</template>

<script>
    import {mapActions, mapState} from "vuex";
    import VueResizable from "vue-resizable";
    import isMobile from "ismobilejs";

    export default {
        name: "VideoWindow",
        data() {
            return {
                positionX: 0,
                positionY: 0,
                zoom: 1,
                rotate: 0,
                flip: 'flip-off',
                prop: null,
                //
                surroundMaskAmount: 0,
                safeZoneAmount: 100,
                centerMarkStyle: 'none',
                frameLines:[],
                //
                centerMarkLoaded: false,
                containImgHeight: false,
                loaded: false,
                isFull: false,
                videoElement: null,
                paused: null,
                showLabels: true,
                streaming: false,
                reff: null,
                chatToken: null,
                showViewer: false,
                hasChat: false,
                showChat: false,
                iframeChatUrl: null,
                isMuted: true,
                volumeTimeoutId: null,
                rangeVolume: 0,
                lastVolumeValue: 100,
                millicastMedia: null,

                stream: null,
                devices: null,
                activeCam: {label: 'none'},
                activeMic: {label: 'none'},
                disabledBandwidthBtn: false,
                disabledSimulcastBtn: false,
                disabledVideoFormatBtn: false,
                mediaSettings: {
                    format: 'h264',
                    simulcast: false,
                    stereo: true,
                    bitrate: 'Unlimited'
                },
                previousMediaSettings: {},
                constraints: {
                    audio: true,
                    video: {
                        width: {min: 640, max: 1920, ideal: 1280},
                        height: {min: 480, max: 1080, ideal: 720},
                        frameRate: {min: 10, max: 60, ideal: 24},
                        advanced: [
                            // additional constraints go here, tried in order until something succeeds
                            // can attempt high level exact constraints, slowly falling back to lower ones
                            {aspectRatio: 16 / 9},
                            {aspectRatio: 4 / 3},
                        ]
                    }
                },
                selectedConstraints: {
                    audio: {
                        echoCancellation: false,
                        //channelCount: {ideal:2}
                    },
                    video: {
                        width: 1280,
                        height: 720,
                        frameRate: 24,
                        aspectRatio: 16 / 9,
                    }
                },
                previousConstraints: {},
                micEnabled: false,
                camEnabled: false,
                director: null,
                websocket: null,
                peer: null,
                localSDP: null,
                remoteSDP: null,
                selectedCameraLabel: '',
                selectedCameraStreamName: null,
                showMediaSettingsDialog: false,
                dialogMediaSettingsLoading: false,
            }
        },
        // eslint-disable-next-line vue/no-unused-components
        components:{VueResizable},
        computed: {
            ...mapState({
                selectedSite(state) {
                    return state.site.selectedSite
                },
                subscribeToken(state) {
                    return state.subscribe.subscribeToken
                },
                fxSettings(state){
                    return state.fxsettings.settings
                },
                activeFrameLines(state){
                    return state.fxsettings.activeFrameLines
                },
                activeTransforms(state){
                    return state.fxsettings.activeTransforms
                },
                activeCenterMarkers(state){
                    return state.fxsettings.activeCenterMarkers
                },
                activeMasters(state){
                    return state.fxsettings.activeMasters
                },
            }),
            /*getVideoFlip(){
                this.flip;
                return `scale(${this.zoom}) rotate(${this.rotate}deg)`;
            },*/
            hasTransform() {
                //console.log('hasCenterMark - ', this.centerMarkStyle && this.centerMarkStyle !== 'none');
                return this.activeTransforms.indexOf(`${this.windowStream.streamName}`)>-1;
            },

            getVideoTransform(){
                let transformValue = '';
                if(this.hasTransform){
                    transformValue = `scale(${this.zoom}) rotate(${this.rotate}deg)  translateX(${this.positionX}px)  translateY(${this.positionY}px) `;
                    if(this.flip === 'flip-horizontal'){
                        transformValue = transformValue.concat(`rotateX(180deg) rotateY(0)`)
                    }else if(this.flip === 'flip-vertically'){
                        transformValue = transformValue.concat(`rotateX(0) rotateY(180deg)`)
                    }else if(this.flip === 'flip-both'){
                        transformValue = transformValue.concat(`rotateX(180deg) rotateY(180deg)`)
                    }else{
                        transformValue = transformValue.concat(`rotateX(0) rotateY(0)`)
                    }
                }else{
                    transformValue = `scale(1) rotate(0deg)  translateX(0px)  translateY(0px) rotateX(0) rotateY(0)`;
                }
                const result = { transform: transformValue };
                //console.log('getVideoTransform -- ',result);
                return result;
            },
            hasMaster() {
                //console.log('hasCenterMark - ', this.centerMarkStyle && this.centerMarkStyle !== 'none');
                return this.activeMasters.indexOf(`${this.windowStream.streamName}`)>-1;
            },

            hasCenterMark() {
                //console.log('hasCenterMark - ', this.centerMarkStyle && this.centerMarkStyle !== 'none');
                return this.centerMarkStyle && this.centerMarkStyle !== 'none' && this.activeCenterMarkers.indexOf(`${this.windowStream.streamName}`)>-1;
            },

            viewBox() {
                return `${this.getMaskFrameWidth()} ${this.getMaskFrameHeight()}`;
            },
            getFrameLines(){
                return this.frameLines.length > 0 ? this.frameLines : [];
            },
            getClass() {
                return this.windowType === 'main' ? 'mainWin' : 'thumbWin';
            },
            playing() {
                return !this.paused;
            }
        },

        props: {

            applyFx:{
                type: Boolean,
                default: function () {
                    return false
                }
            },
            /*frameLines:{
                type: Array,
                default: function () {
                    return []
                }
            },*/
            /*safeZoneAmount:{
                type: Number,
                default: function () {
                    return 100
                }
            },*/
            /*centerMarkStyle:{
                type: String,
                default: function () {
                    return 'none'
                }
            },*/
            /*surroundMaskAmount:{
                type: Number,
                default: function () {
                    return 0
                }
            },*/
            surroundMaskColor:{
                type: String,
                default: function () {
                    return '#000000'
                }
            },
            windowStream: {
                type: Object,
                required: true,
                default: function () {
                    return null
                }
            },
            windowType: {},
            token: {
                type: Object,
                //required: true,
                default: function () {
                    return {}
                }
            },
            status: {
                type: String,
                default: function () {
                    return 'inactive'
                }
            },
            onSelected: {
                type: Function,
            },
            onStatus: {
                type: Function,
            }
        },
        methods: {
            ...mapActions({
                'getStream': 'subscribe/getStream',
            }),
            hasFrameLine(frameLine, index){
                return frameLine.aspectRatio>0 && this.activeFrameLines.indexOf(`${this.windowStream.streamName}-${index}`)>-1;
            },
            onImgLoad(){
                this.centerMarkLoaded = false;
                setTimeout(()=>{
                    this.centerMarkLoaded = true;
                }, 100)
            },
            //:transform="getMaskTranslate()" :width="getMaskFrameWidth()" :height="getMaskFrameHeight()"
            getMaskColor(){
                //const frameLine = this.frameLines[0];
                //console.log(!frameLine);
                return this.surroundMaskColor;
            },
            getMaskTranslate(){
                const frameHeight = this.getMaskFrameHeight();
                const frameWidth = this.getMaskFrameWidth();

                return `translate(-${frameWidth/2},-${frameHeight/2})`;
            },
            getMaskFrameWidth(){
                let response;
                let widths = [];
                for(let i = 0; i<this.frameLines.length; i++){
                    const frameLine = this.frameLines[i];
                    if(frameLine.aspectRatio>0){
                        const frameWidth = this.getFrameWidth(frameLine.aspectRatio, null, null, frameLine.resize);
                        widths.push(frameWidth);
                    }
                }
                response = Math.min(...widths) === Infinity ? 0 : Math.min(...widths);
                //console.log('getMaskFrameWidth - ', this.windowStream.streamName, ' - ', response);
                return response;
            },
            getMaskFrameHeight(){
                let response;
                let heights = [];
                for(let i = 0; i<this.frameLines.length; i++){
                    const frameLine = this.frameLines[i];
                    if(frameLine.aspectRatio>0){
                        const frameHeight = this.getFrameHeight(frameLine.aspectRatio, null, null, frameLine.resize);
                        heights.push(frameHeight);
                    }
                }
                response = Math.min(...heights) === Infinity ? 0 : Math.min(...heights);
                //console.log('getMaskFrameHeight - ', this.windowStream.streamName, ' - ', response);
                return response;
            },
            getTranslate(val){
                const frameHeight = this.getFrameHeight(val);
                const frameWidth = this.getFrameWidth(val);

                return `translate(-${frameWidth/2},-${frameHeight/2})`;
            },
            getFrameHeight(val, _parentWidth = null, _parentHeight = null, safeZoneAmount = null){
                if(val === 0)return;
                const vidWin = this.$refs[this.reff];
                const parentWidth = _parentWidth ? _parentWidth : vidWin.parentNode.parentElement.clientWidth;
                const parentHeight = _parentHeight ? _parentHeight : vidWin.parentNode.parentElement.clientHeight;

                const aspectRatio = val;

                let preferredWidth = parentWidth;
                let preferredHeight = preferredWidth/aspectRatio;
                let diff = preferredHeight/parentHeight;


                let monitorWidth = parentWidth;
                let monitorHeight = monitorWidth/1.78;
                let monitorDiff =  monitorHeight/parentHeight;

                let pWidth;
                let pHeight;

                if(monitorHeight>parentHeight){
                    //console.log('monitorWidth:monitorHeight ');
                    monitorWidth = (monitorWidth / monitorDiff);
                    monitorHeight = (monitorHeight / monitorDiff);
                }
                //console.log('monitorWidth:monitorHeight ', monitorWidth, monitorHeight);

                if(preferredHeight>parentHeight){
                    preferredWidth = (preferredWidth / diff);
                    preferredHeight = (preferredHeight / diff);
                }
                //console.log('preferredWidth:preferredHeight ', preferredWidth, preferredHeight);

                if(preferredHeight>monitorHeight){
                    preferredWidth = monitorHeight*aspectRatio;
                    preferredHeight = monitorHeight;
                }

                if(preferredWidth>monitorWidth){
                    pWidth = monitorWidth;
                    pHeight = monitorWidth/aspectRatio;
                }else{
                    // eslint-disable-next-line no-unused-vars
                    pWidth = preferredWidth;
                    pHeight = preferredHeight;
                }

                if(safeZoneAmount && !this.hasMaster){
                    return pHeight*(safeZoneAmount/100);
                }
                //console.log('pWidth:pHeight ', pWidth, pHeight);
                return pHeight*(this.safeZoneAmount/100);

            },
            getFrameWidth(val, _parentWidth = null, _parentHeight = null, safeZoneAmount = null){
                if(val === 0)return;
                const vidWin = this.$refs[this.reff];
                const parentWidth = _parentWidth ? _parentWidth : vidWin.parentNode.parentElement.clientWidth;
                const parentHeight = _parentHeight ? _parentHeight : vidWin.parentNode.parentElement.clientHeight;

                const aspectRatio = val;

                let preferredWidth = parentWidth;
                let preferredHeight = preferredWidth/aspectRatio;
                let diff = preferredHeight/parentHeight;


                let monitorWidth = parentWidth;
                let monitorHeight = monitorWidth/1.78;
                let monitorDiff =  monitorHeight/parentHeight;

                let pWidth;
                let pHeight;

                if(monitorHeight>parentHeight){
                    //console.log('monitorWidth:monitorHeight ');
                    monitorWidth = (monitorWidth / monitorDiff);
                    monitorHeight = (monitorHeight / monitorDiff);
                }
                //console.log('monitorWidth:monitorHeight ', monitorWidth, monitorHeight);

                if(preferredHeight>parentHeight){
                    preferredWidth = (preferredWidth / diff);
                    preferredHeight = (preferredHeight / diff);
                }
               // console.log('preferredWidth:preferredHeight ', preferredWidth, preferredHeight);

                if(preferredHeight>monitorHeight){
                    preferredWidth = monitorHeight*aspectRatio;
                    preferredHeight = monitorHeight;
                }

                if(preferredWidth>monitorWidth){
                    pWidth = monitorWidth;
                    pHeight = monitorWidth/aspectRatio;
                }else{
                    pWidth = preferredWidth;
                    // eslint-disable-next-line no-unused-vars
                    pHeight = preferredHeight;
                }

                if(safeZoneAmount && !this.hasMaster){
                    return pWidth*(safeZoneAmount/100);
                }

                //console.log('pWidth:pHeight ', pWidth, pHeight);
                return pWidth*(this.safeZoneAmount/100);

            },
            launchFullscreen(el) {
                this.isFull = true;
                let element = document.documentElement;
                if (el) element = el;
                if (element.requestFullscreen) {
                    element.requestFullscreen();
                } else if (element.mozRequestFullScreen) {
                    element.mozRequestFullScreen();
                } else if (element.webkitRequestFullscreen) {
                    element.webkitRequestFullscreen();
                } else if (element.msRequestFullscreen) {
                    element.msRequestFullscreen();
                } else {
                    this.isFull = false;
                }
            },
            exitFullscreen() {
                this.isFull = false;
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else {
                    this.isFull = true;
                }
            },

            updatePaused(event) {
                this.videoElement = event.target;
                this.paused = event.target.paused;
            },
            play() {
                this.videoElement.play();
            },
            pause() {
                this.videoElement.pause();
            },
            onMouseover() {
                //this.videoPlay();
            },
            onMouseleave() {
                //this.videoPause();
            },
            addVideoForStream(stream) {
                let vidWin = this.$refs[this.reff];

                if (vidWin) {
                    vidWin.srcObject = stream;
                    //console.log('addVideoForStream', vidWin);
                    this.loaded = true;
                }

                vidWin.onplay = () => {
                    //console.log('onplay');
                    if (this.windowType === 'main') {
                        //vidWin.classList.remove("w-100 h-100");
                    }
                }
            },

            videoPlay() {
                const playPromise = this.$refs[this.reff].play();
                if (playPromise !== undefined) {
                    playPromise.then(() => {
                        // Automatic playback started!
                        // Show playing UI.
                    })
                        .catch(() => {
                            // Auto-play was prevented
                            // Show paused UI.
                        });
                }
                this.showLabels = false;
            },
            videoPause() {
                this.$refs[this.reff].pause();
                this.showLabels = true;
            },

            setMute() {
                const video = this.$refs[this.reff];
                video.muted = video.muted === false;
                //console.log('onMute - ', video.muted);
                if (video.muted === true) {
                    this.setVolume(0);
                } else {
                    this.setVolume(!this.lastVolumeValue ? 100 : this.lastVolumeValue);
                }
                //console.log('setMute: muted', video.muted, ' volume:', video.volume, ' isMuted ', this.isMuted);
            },
            setVolume(val) {
                //const val = !value ? value: this.rangeVolume;
                const video = this.$refs[this.reff];
                //console.log('setVolume Before: ', val, ' - ', video.volume);
                this.lastVolumeValue = video.volume * 100;
                video.volume = Math.floor(val) / 100;

                this.isMuted = !(video.volume > 0);
                if (this.isMuted !== video.muted) video.muted = this.isMuted;
                if(this.volumeTimeoutId)clearTimeout(this.volumeTimeoutId);
                this.volumeTimeoutId = setTimeout(() => {
                    if(this.rangeVolume !== val)this.rangeVolume = Math.floor(val);
                }, 100);
                //console.log('setVolume: muted', video.muted, ' volume:', video.volume, ' isMuted ', this.isMuted);
            },

            onClick() {
                //// console.log(this.windowStream)
                if (this.onSelected) this.onSelected(this.windowStream)
            },
        },
        created() {
            console.log("created ", this.windowStream);
            //this.loaded = false;
            this.reff = `vid-${this.windowStream.streamName}`;

            if (this.windowStream.src) {
                setTimeout(() => {
                    this.addVideoForStream(this.windowStream.src.stream);
                }, 50);
            }

            if(!isMobile(window.navigator).phone && !isMobile(window.navigator).tablet){
                this.containImgHeight = true
            }
        },
        watch: {
            fxSettings:{
                handler: function(newVal){
                    console.log('fxSettings fxSettings ', newVal);
                    newVal.forEach((setting)=>{
                        if (setting.streamName === this.windowStream.streamName) {
                            this.frameLines = setting.frameLines;
                            this.centerMarkStyle = setting.centerMarker.type;
                            this.safeZoneAmount = setting.master.resize;
                            this.surroundMaskAmount = setting.master.shading;
                            this.zoom = setting.transform.zoom/100;
                            this.rotate = setting.transform.rotate;
                            this.flip = setting.transform.flip;
                            this.positionX = setting.transform.positionX;
                            this.positionY = setting.transform.positionY;
                        }
                    })
                },
                deep: true,
            },

            rangeVolume: {
                handler: function (newVal) {
                    this.setVolume(Math.floor(newVal));
                },
            },
            windowStream:{
                // eslint-disable-next-line no-unused-vars
                handler: function (newVal, oldVal) {
                    const b = JSON.stringify(newVal) === JSON.stringify(oldVal);
                    //console.log(this.reff, ' -- windowStream - updated ', !b);
                    if(b)return;
                    //console.log(this.reff, ' -- windowStream - ', newVal, oldVal, JSON.stringify(newVal) === JSON.stringify(oldVal));
                    if(newVal.streamName !== oldVal.streamName)this.loaded = false;

                    if (newVal.src && !this.loaded) {
                        this.reff = `vid-${newVal.streamName}`;
                        setTimeout(() => {
                            this.addVideoForStream(newVal.src.stream);
                        }, 50);
                    }
                }
            }


        }
    }
</script>
<style>
    .bottom-bar-fullscreen{
        position: absolute;
        bottom: 0;
    }
    .volume{
        margin-top: 15px !important;
        margin-right: 0px !important;
    }
    .block {
        height: 100%;
        width: 100%;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .table-block {
        display: table;
    }
    .table-row {
        display: table-row;
        text-align: center;
    }
    .table-cell {
        width: 50%;
        display: inline-block
    }
    .table-input {
        width: 50px
    }

    .table-container {
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: 600px) {
        .volume{
            margin-top: 15px !important;
            max-width: 100px !important;
            margin-right: 0px !important;
        }
    }
</style>
<style scoped>
    .contain-img{
        width: 100%;
        object-fit: cover;
        overflow: hidden;
    }
    .resizable-b {
        height: 6px !important;
        background-color: #ffffff;
    }

    .vid-win-inactive {
        height: calc(100%);
    }

    .vid-win-active {
        height: calc(100% - 36px) !important;
    }

    .viewer-controls > .md-button {
        min-width: 25px;
        margin-top: 0;
        margin-bottom: 0;
    }

    .thumbWin, .vidWin {
        min-width: 100px;
        min-height: 100px;
    }

    .thumbWin {
        height: 100% !important;
        /*max-height: 200px;*/
    }

    .mainWin {
        /*max-width: calc( 1280px - 640px );*/
        /*max-width: 640px;*/
    }

    .position-relative {
        position: relative;
    }

    .ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .top-0 {
        top: 0;
    }



</style>
